<template>
  <div
    class="dashboard-header align-items-center d-flex justify-content-between"
  >
    <router-link to="/" class="nav-logo js-anchor-link navbar-brand">
      <img src="@/assets/images/logo.png" alt="" srcset="" />
    </router-link>

    <div class="d-flex align-items-center">
      <img src="@/assets/images/notification.png" alt="" />
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              to="/"
              class="nav-link px-3 medium js-anchor-link text-dark"
            >
              Home
            </router-link>
          </li>
        </ul>
      </div>
      <div class="dropdown-center ms-3">
        <button
          class="border-0 bg-transparent dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src="@/assets/images/user-admin.png" alt="" />
        </button>
        <ul class="dropdown-menu">
          <li>
            <router-link to="/dashboard">
              <a class="dropdown-item"> Dahsboard </a>
            </router-link>
          </li>
          <li>
            <router-link to="/login">
              <a class="dropdown-item" @click="logout"> Logout </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("tokenExpiration");
      this.$router.push("/login");
    },
  },
};
</script>
  
<style scoped>
.dashboard-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  font-size: 2rem;

  text-align: center;
}

.dashboard-message h1 {
  font-size: inherit;
}

@import "../assets/css/dashboard.css";
</style>
  