<template>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card">
            <div class="card-body">
              <h2 class="card-title text-center">Thank You for Your Payment</h2>
              <p class="card-text text-center">Your payment has been successfully processed.</p>
              <p class="card-text text-center">An email receipt has been sent to your inbox.</p>
              <div class="text-center">
                <router-link to="/" class="btn btn-primary">Back to Home</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    // You can add any necessary logic or data here
  };
  </script>
  