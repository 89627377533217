<template>
  <div>
    <!-- <div class="grad-header"></div> -->
    <!-- <header class="header px-lg-0 px-3 px-sm-3">
      <nav class="navbar">
        <router-link to="/" class="nav-logo js-anchor-link">
          <img src="@/assets/images/logo.png" alt="" srcset="" />
        </router-link>
        <ul class="nav-menu mb-0 phs-0">

          <li class="nav-item activeMenu">
            <router-link to="/" class="nav-link px-3 medium js-anchor-link activeMenu">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/resume-templates" class="nav-link px-3 medium js-anchor-link">Templates</router-link>

          </li>
          <li class="nav-item">
            <router-link to="/how-to-write-resume" class="nav-link px-3 medium js-anchor-link">How to
              Write</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/FAQ" class="nav-link px-3 medium js-anchor-link">FAQs</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/login">
              <button
                class="hero-primary-btn d-flex align-items-center px-5 h-auto py-sm-2 ms-auto w-100 justify-content-center bg-blue">
                Login
              </button>
            </router-link>
          </li>
        </ul>
        <div class="hamburger">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </div>
      </nav>
    </header> -->

    <!-- navbar -->

    <FrontendHeader />

    <section class="position-relative leftrightpadding">
      <div
        class="d-flex w-100 align-items-center justify-content-center h-100 m-auto"
      >
        <div class="grad-mid-hero"></div>
      </div>
      <div class="custom-container m-auto">
        <div class="row align-items-center mt-4">
          <div class="col-lg-6">
            <div>
              <router-link to="/build-resume-personal-summary">
                <button class="btn-hero">Online Resume Builder</button>
              </router-link>
            </div>
            <h1 class="hero-title extrabold ttu py-3 mb-0">
              We help you get <br />
              <span class="text-009473 extrabold">hired!!</span>
              <!-- at top companies. -->
            </h1>
            <p class="fs-18 mb-0">
              Create an impressive online resume with the best free resume
              builder.
            </p>
            <div>
              <router-link to="/build-resume-personal-summary">
                <button
                  class="hero-primary-btn d-flex align-items-center justify-content-center bg-blue mt-3"
                >
                  Build Resume
                </button>
              </router-link>
            </div>
            <div class="medium pt-4 fs-18">Loved by interviewers at</div>
            <div
              class="d-flex pt-3 align-items-center flex-wrap justify-content-center justify-content-lg-start justify-content-sm-center"
            >
              <img src="@/assets/images/microsoft.png" alt="" srcset="" />
              <img
                src="@/assets/images/tesla.png"
                class="ms-3"
                alt=""
                srcset=""
              />
              <img
                src="@/assets/images/netflix.png"
                class="ms-3"
                alt=""
                srcset=""
              />
              <img
                src="@/assets/images/elastic.png"
                class="ms-3"
                alt=""
                srcset=""
              />
              <img
                src="@/assets/images/adobexd.png"
                class="ms-3"
                alt=""
                srcset=""
              />
              <img
                src="@/assets/images/uber.png"
                class="ms-3"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div class="col-lg-6 position-relative">
            <img
              src="@/assets/images/pdf-hero.png"
              class="w-100"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </section>
    <section class="container">
      <swiper
        :slides-per-view="3"
        :space-between="10"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide style="">
          <div class="img-div">
            <img
              style="border: 1px solid #009473; border-radius: 10px"
              src="@/assets/images/1.png"
              width="auto"
              height="400"
              class="w-100"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>
        <swiper-slide style="">
          <div class="img-div">
            <img
              style="border: 1px solid #009473; border-radius: 10px"
              src="@/assets/images/2.png"
              width="auto"
              height="400"
              class="w-100"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>
        <swiper-slide style="">
          <div class="img-div">
            <img
              style="border: 1px solid #009473; border-radius: 10px"
              src="@/assets/images/3.png"
              width="auto"
              height="400"
              class="w-100"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>
        <swiper-slide style="">
          <div class="img-div">
            <img
              style="border: 1px solid #009473; border-radius: 10px"
              src="@/assets/images/4.png"
              width="auto"
              height="400"
              class="w-100"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>
        <swiper-slide style="">
          <div class="img-div">
            <img
              style="border: 1px solid #009473; border-radius: 10px"
              src="@/assets/images/5.png"
              width="auto"
              height="400"
              class="w-100"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>
        <swiper-slide style="">
          <div class="img-div">
            <img
              style="border: 1px solid #009473; border-radius: 10px"
              src="@/assets/images/5.png"
              width="auto"
              height="400"
              class="w-100"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>
        <swiper-slide style="">
          <div class="img-div">
            <img
              style="border: 1px solid #009473; border-radius: 10px"
              src="@/assets/images/template6.png"
              width="auto"
              height="400"
              class="w-100"
              alt=""
              srcset=""
            />
          </div>
        </swiper-slide>
      </swiper>

      <div class="mt-5">
        <div class="btnTemplatecarousel">
          <router-link to="/build-resume-personal-summary">
            <button class="text-white">Use This Template</button>
          </router-link>
        </div>
      </div>
      <div class="btnTemplatecarouselBrowse">
        <a class="d-flex justify-content-center mt-5">
          <div>
            <router-link
              to="/resume-templates"
              class="text-blue fs-18 medium tdu"
              >Browse All Templates</router-link
            >
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="22"
            viewBox="0 0 24 25"
            fill="#0063B2"
          >
            <path
              d="M14.43 19.538C14.24 19.538 14.05 19.468 13.9 19.318C13.61 19.028 13.61 18.548 13.9 18.258L19.44 12.718L13.9 7.17801C13.61 6.88801 13.61 6.40801 13.9 6.11801C14.19 5.82801 14.67 5.82801 14.96 6.11801L21.03 12.188C21.32 12.478 21.32 12.958 21.03 13.248L14.96 19.318C14.81 19.468 14.62 19.538 14.43 19.538Z"
              fill="#0063B2"
            />
            <path
              d="M20.33 13.468H3.5C3.09 13.468 2.75 13.128 2.75 12.718C2.75 12.308 3.09 11.968 3.5 11.968H20.33C20.74 11.968 21.08 12.308 21.08 12.718C21.08 13.128 20.74 13.468 20.33 13.468Z"
              fill="#0063B2"
            />
          </svg>
        </a>
      </div>
    </section>
    <section class="mainmargintop">
      <div class="custom-container m-auto">
        <h1 class="bold fs-30 text-center mb-0 px-sm-3 px-3 px-lg-0 px-md-3">
          Create Your Impressive Resume in 3 Easy Steps
        </h1>

        <div class="mt-5 row px-sm-3 px-3 px-lg-0 px-md-3">
          <div
            class="col-lg-4 mb-lg-0 mb-sm-4 mb-4 text-center position-relative"
          >
            <img
              src="@/assets/images/curve1.png"
              class="curve-1"
              alt=""
              srcset=""
            />
            <img src="@/assets/images/document-text.png" alt="" srcset="" />
            <h4 class="bold text-black pt-4 mb-0">Select a Template</h4>
            <p class="pt-3 mb-0">
              Choose from our extensive collection of industry-specific
              templates that match your style and career aspirations.
            </p>
          </div>
          <div
            class="col-lg-4 mb-lg-0 mb-sm-4 mb-4 text-center position-relative"
          >
            <img
              src="@/assets/images/curve2.png"
              class="curve-2"
              alt=""
              srcset=""
            />

            <img src="@/assets/images/edit.png" alt="" srcset="" />
            <h4 class="bold text-black pt-4 mb-0">Customize Your Content</h4>
            <p class="pt-3 mb-0">
              Personalize each section of your resume, including contact
              information, professional summary, work experience, education, and
              skills.
            </p>
          </div>
          <div
            class="col-lg-4 mb-lg-0 mb-sm-4 mb-4 text-center position-relative"
          >
            <img
              src="@/assets/images/curve3.png"
              alt=""
              class="curve-3"
              srcset=""
            />
            <img src="@/assets/images/document-download.png" alt="" srcset="" />
            <h4 class="bold text-black pt-4 mb-0">Download and Share</h4>
            <p class="pt-3 mb-0">
              With just one click, download your professionally designed resume
              in various formats (PDF, Word, HTML) for easy sharing and
              printing.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="mainmargintop px-sm-3 px-3 px-lg-0 px-md-3">
      <div
        class="custom-container px-lg-5 px-sm-4 px-4 pt-lg-5 pt-sm-4 pt-4 better-resume-homesec m-auto"
      >
        <div
          class="row align-items-center flex-sm-column-reverse flex-column-reverse flex-lg-row"
        >
          <div class="col-lg-6">
            <img
              src="@/assets/images/better-resume.png"
              class="w-100 mt-lg-0 mt-sm-4 mt-4"
              alt=""
              srcset=""
            />
          </div>
          <div class="col-lg-6">
            <h3 class="fs-30 bold mb-0 text-white">
              Use the best resume <br />
              maker as your guide
            </h3>
            <p class="text-white mb-0 pt-3">
              A great job application leads to a good interview. An amazing
              resume is what makes it all possible. Start off strong with the
              hiring manager by creating a positive professional image. A job
              interview can be much easier if they have a favorable view of your
              CV and cover letter.
            </p>
            <div>
              <router-link to="/build-resume-personal-summary">
                <button
                  class="hero-primary-btn d-flex align-items-center justify-content-center bg-blue mt-3"
                >
                  Build Resume
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mainmargintop">
      <div class="custom-container m-auto">
        <div class="row px-lg-5 px-sm-3 px-3 align-items-center">
          <div class="col-lg-6">
            <h3 class="fs-30 bold mb-0">
              Use the best resume maker <br />
              as your guide
            </h3>
            <p class="mb-0 pt-3">
              Getting that dream job can seem like an impossible task. We’re
              here to change that. Give yourself a real advantage with the best
              online resume maker: created by experts, improved by data, trusted
              by millions of professionals.
            </p>
            <div>
              <router-link to="/build-resume-personal-summary">
                <button
                  class="hero-primary-btn d-flex align-items-center justify-content-center bg-blue mt-3"
                >
                  Build Resume
                </button>
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 mt-lg-0 mt-sm-4 mt-4">
            <img
              src="@/assets/images/Group-6528.png"
              class="w-100"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </section>
    <section class="mainmargintop">
      <div class="custom-container m-auto">
        <h1 class="bold fs-30 text-center mb-0">
          Create Your Impressive Resume in 3 Easy Steps
        </h1>

        <div class="mt-5 row px-lg-5 px-sm-3 px-3">
          <div class="col-lg-4 text-center position-relative">
            <img src="@/assets/images/cup.png" alt="" srcset="" />
            <h4 class="bold text-black pt-4 mb-0">
              Make a resume <br />
              that wins interviews
            </h4>
            <p class="pt-3 mb-0">
              Use our resume maker with its advanced creation tools to tell a
              professional story that engages recruiters, hiring managers and
              even CEOs.
            </p>
          </div>
          <div class="col-lg-4 text-center position-relative">
            <img src="@/assets/images/note.png" alt="" srcset="" />
            <h4 class="bold text-black pt-4 mb-0">
              Resume writing <br />
              made easy
            </h4>
            <p class="pt-3 mb-0">
              Resume writing has never been this effortless. Pre-generated text,
              visual designs and more - all already integrated into the resume
              maker. Just fill in your details.
            </p>
          </div>
          <div class="col-lg-4 text-center">
            <img src="@/assets/images/like.png" alt="" srcset="" />
            <h4 class="bold text-black pt-4 mb-0">
              A recruiter-tested <br />
              CV maker tool
            </h4>
            <p class="pt-3 mb-0">
              Our resume builder and its pre-generated content are tested by
              recruiters and IT experts. We help your CV become truly
              competitive in the hiring process.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="mainmargintop">
      <div class="custom-container px-lg-5 px-sm-3 px-3 m-auto">
        <h1 class="bold fs-30 text-center mb-5">What People say about us</h1>
        <div class="my-5 position-relative swiper-two">
          <div class="">
            <!-- <swiper :slides-per-view="2" :space-between="10" @swiper="onSwiper" @slideChange="onSlideChange">
              <swiper-slide style="">
                <div class="reviews-sec p-3">
                  <div style="border: 1px solid #009473; border-radius: 10px;  padding:10px" class="d-flex">
                    <div class="">
                      <img src="@/assets/images/re-2.png" width="183px" alt="" srcset="" />
                    </div>
                    <div class="ps-lg-3 w-100">
                      <div class="text-end x mb-2">
                        <img src="@/assets/images//Quotes.png" class="ms-auto" alt="" srcset="" />
                      </div>
                      <p class="text-lg-start text-sm-justify text-justify">
                        This resume builder is a gem! It saved me hours of formatting and gave my resume a professional
                        edge. The AI suggestions are spot-on.
                      </p>
                      <h6 class="bold text-lg-start text-sm-justify text-justify text-black">
                        Thrilled Job Hunter
                      </h6>
                      <div class="text-start">Career Changer</div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="">
                <div class="reviews-sec p-3">
                  <div style="border: 1px solid #009473; border-radius: 10px; padding:10px" class="d-flex">
                    <div class="">
                      <img src="@/assets/images/re-2.png" width="183px" alt="" srcset="" />
                    </div>
                    <div class="ps-lg-3 w-100">
                      <div class="text-end x mb-2">
                        <img src="@/assets/images//Quotes.png" class="ms-auto" alt="" srcset="" />
                      </div>
                      <p class="text-lg-start text-sm-justify text-justify">
                        I've used many resume builders, but this one stands out. It's intuitive, efficient, and the
                        results are impressive. A must-have for job seekers!
                      </p>
                      <h6 class="bold text-lg-start text-sm-justify text-justify text-black">
                        Career Advancer
                      </h6>
                      <div class="text-start">Professional</div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="">
                <div class="reviews-sec p-3">
                  <div style="border: 1px solid #009473; border-radius: 10px; padding:10px" class="d-flex">
                    <div class="">
                      <img src="@/assets/images/re-2.png" width="183px" alt="" srcset="" />
                    </div>
                    <div class="ps-lg-3 w-100">
                      <div class="text-end x mb-2">
                        <img src="@/assets/images//Quotes.png" class="ms-auto" alt="" srcset="" />
                      </div>
                      <p class="text-lg-start text-sm-justify text-justify">
                        This resume builder exceeded my expectations. It's user-friendly and made my resume shine. The
                        templates are modern, and I got hired quickly.
                      </p>
                      <h6 class="bold text-lg-start text-sm-justify text-justify text-black">
                        Satisfied Job Seeker
                      </h6>
                      <div class="text-start">Recent Graduate</div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

            </swiper> -->
          </div>
          <swiper
            :spaceBetween="30"
            :slidesPerView="2"
            :slidesPerGroup="1"
            :loop="true"
            :navigation="true"
            :pagination="true"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
            :breakpoints="{
              '300': {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              '540': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '768': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '1024': {
                slidesPerView: 2,
                spaceBetween: 40,
              },
            }"
            :modules="modules"
            class="mySwiper"
          >
            <swiper-slide style="">
              <div>
                <div class="reviews-sec">
                  <div
                    style="border: 1px solid #009473; border-radius: 10px"
                    class="d-flex"
                  >
                    <div class="">
                      <img
                        src="@/assets/images/re-2.png"
                        width="183px"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="p-3 w-100">
                      <div class="text-end x mb-2">
                        <img
                          src="@/assets/images/Quotes.png"
                          class="ms-auto"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <p class="text-lg-start text-sm-justify text-justify">
                        Aut nihil mollitia deserunt quia sed rem. Quibusdam amet
                        veniam rerum id rerum beatae. Quas rerum iste
                        necessitatibus. At voluptates ad magnam blanditiis
                        excepturi expedita aut. Aut repellat inventore qui
                        minima illum es1t.
                      </p>
                      <h6
                        class="bold text-lg-start text-sm-justify text-justify text-black"
                      >
                        Kyle Merwin
                      </h6>
                      <div class="text-start">CO-owner</div>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide style="">
              <div>
                <div class="reviews-sec">
                  <div
                    style="border: 1px solid #009473; border-radius: 10px"
                    class="d-flex"
                  >
                    <div class="">
                      <img
                        src="@/assets/images/re-2.png"
                        width="183px"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="p-3 w-100">
                      <div class="text-end x mb-2">
                        <img
                          src="@/assets/images/Quotes.png"
                          class="ms-auto"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <p class="text-lg-start text-sm-justify text-justify">
                        Aut nihil mollitia deserunt quia sed rem. Quibusdam amet
                        veniam rerum id rerum beatae. Quas rerum iste
                        necessitatibus. At voluptates ad magnam blanditiis
                        excepturi expedita aut. Aut repellat inventore qui
                        minima illum es1t.
                      </p>
                      <h6
                        class="bold text-lg-start text-sm-justify text-justify text-black"
                      >
                        Kyle Merwin
                      </h6>
                      <div class="text-start">CO-owner</div>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide style="">
              <div>
                <div class="reviews-sec">
                  <div
                    style="border: 1px solid #009473; border-radius: 10px"
                    class="d-flex"
                  >
                    <div class="">
                      <img
                        src="@/assets/images/re-2.png"
                        width="183px"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="p-3 w-100">
                      <div class="text-end x mb-2">
                        <img
                          src="@/assets/images/Quotes.png"
                          class="ms-auto"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <p class="text-lg-start text-sm-justify text-justify">
                        Aut nihil mollitia deserunt quia sed rem. Quibusdam amet
                        veniam rerum id rerum beatae. Quas rerum iste
                        necessitatibus. At voluptates ad magnam blanditiis
                        excepturi expedita aut. Aut repellat inventore qui
                        minima illum es1t.
                      </p>
                      <h6
                        class="bold text-lg-start text-sm-justify text-justify text-black"
                      >
                        Kyle Merwin
                      </h6>
                      <div class="text-start">CO-owner</div>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide style="">
              <div>
                <div class="reviews-sec">
                  <div
                    style="border: 1px solid #009473; border-radius: 10px"
                    class="d-flex"
                  >
                    <div class="">
                      <img
                        src="@/assets/images/re-2.png"
                        width="183px"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="p-3 w-100">
                      <div class="text-end x mb-2">
                        <img
                          src="@/assets/images/Quotes.png"
                          class="ms-auto"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <p class="text-lg-start text-sm-justify text-justify">
                        Aut nihil mollitia deserunt quia sed rem. Quibusdam amet
                        veniam rerum id rerum beatae. Quas rerum iste
                        necessitatibus. At voluptates ad magnam blanditiis
                        excepturi expedita aut. Aut repellat inventore qui
                        minima illum es1t.
                      </p>
                      <h6
                        class="bold text-lg-start text-sm-justify text-justify text-black"
                      >
                        Kyle Merwin
                      </h6>
                      <div class="text-start">CO-owner</div>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
    <footer class="bg-black pt-5">
      <div class="custom-container px-lg-5 px-sm-3 px-3 py-lg-4 m-auto">
        <div class="row w-100">
          <div class="col-lg-3 mb-lg-0 mb-sm-4 mb-4">
            <img src="@/assets/images/white-logo.png" alt="" srcset="" />
            <div class="text-white pt-3">
              <span class="medium">Job Crafted:</span> Crafting Your Path to a
              Successful Career
            </div>
            <div class="d-flex pt-4">
              <img
                class="cursor-pointer"
                src="@/assets/images/fb.png"
                alt=""
                srcset=""
              />
              <img
                class="cursor-pointer ms-3"
                src="@/assets/images/instagram.png"
                alt=""
                srcset=""
              />
              <img
                class="cursor-pointer ms-3"
                src="@/assets/images/whatsapp.png"
                alt=""
                srcset=""
              />
              <img
                class="cursor-pointer ms-3"
                src="@/assets/images/linkedin.png"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div
            class="col-lg-3 mb-lg-0 mb-sm-4 mb-4 d-flex align-items-center justify-content-lg-center"
          >
            <div class="">
              <div class="bold text-white">Resources</div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Blog</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Career Advice</a>
              </div>
              <div class="pt-3">
                <!-- <a href="#" class="d-flex light text-white">FAQ</a> -->
                <router-link to="/FAQ" class="d-flex light text-white"
                  >FAQs</router-link
                >
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Terms of Service</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Help & Support</a>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 mb-lg-0 mb-sm-4 mb-4 d-flex justify-content-lg-center"
          >
            <div class="">
              <div class="bold text-white">Company</div>
              <div class="pt-3 -flex">
                <a href="#" class="d-flex light text-white">About Us</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Our Team</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Testimonials</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Privacy Policy</a>
              </div>
            </div>
          </div>
          <div class="col-lg-3 d-flex justify-content-lg-center">
            <div class="">
              <div class="bold text-white">Contact Us</div>
              <div class="pt-3 d-flex align-items-center">
                <img src="@/assets/images/sms.png" alt="" srcset="" />
                <a href="#" class="d-flex light text-white ps-2"
                  >Exapmle@mail.com</a
                >
              </div>
              <div class="pt-3 d-flex align-items-center">
                <img src="@/assets/images/call.png" alt="" srcset="" />
                <a href="#" class="d-flex light text-white ps-2"
                  >+1 123-456-7890</a
                >
              </div>
              <div class="pt-3 d-flex align-items-center">
                <img src="@/assets/images/location.png" alt="" srcset="" />
                <a href="#" class="d-flex light text-white ps-2"
                  >123 Main St, City, State</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-container border-bottom m-auto pt-5"></div>
      <p class="text-white text-center py-4 mb-0">
        © 2023, Job Crafted. All rights reserved
      </p>
    </footer>
  </div>
</template>
  
<script>
import "bootstrap"; // This imports Bootstrap JavaScript
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import FrontendHeader from './FrontendHeader.vue'

// Import Swiper styles
import "swiper/css";

export default {
  name: "HomePage",
  components: {
    Swiper,
    SwiperSlide,
    FrontendHeader
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  methods: {
    logout() {
      // Delete the accessToken key from storage
      localStorage.removeItem("accessToken"); // Replace with your storage mechanism
      localStorage.removeItem("tokenExpiration"); // Replace with your storage mechanism

      // Redirect to the login page
      this.$router.push("/login");
    },
  },
  created() {
    // Check if the user is already logged in based on the presence of accessToken in storage
    const accessToken = localStorage.getItem("accessToken"); // Replace with your storage mechanism

    if (accessToken) {
      this.isLoggedIn = true;
    }
  },
  computed: {
    hasAccessToken() {
      // Check if "accessToken" exists in storage and return true or false accordingly
      const accessToken = localStorage.getItem("accessToken"); // You can replace 'localStorage' with 'sessionStorage' if needed
      return !!accessToken;
    },
  },
};
</script>
  
  <!-- Add your CSS styles here or link to an external CSS file if needed -->
<style scoped>
/* Your CSS styles go here */
@import "../assets/css/global.css";
/* Update the path as needed */
@import "bootstrap/dist/css/bootstrap.min.css";
@media (max-width: 850px) {
  .img-div img {
    height: 90%;
  }
}

@media (max-width: 650px) {
  .img-div img {
    height: 60%;
  }
}

@media (max-width: 550px) {
  .img-div img {
    height: 60%;
  }
}
</style>
  