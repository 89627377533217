<template>
  <section
    class="login-section h-100 d-flex align-items-center justify-content-center m-auto"
  >
    <div class="login-main position-relative">
      <div class="blue-circle-login"></div>
      <h2 class="bold text-center">Signup</h2>

      <div class="pt-4">
        <label for="" class="medium">Email Address</label>
        <input
          class="w-100 custom-input input-height mt-2"
          placeholder="Type your email address here"
          v-model="email"
          type="email"
          name="email"
          id="email"
        />
      </div>
      <div class="pt-4">
        <label for="" class="medium">Password</label>
        <input
          class="w-100 custom-input input-height mt-2"
          placeholder="Type your password here"
          v-model="password"
          type="password"
          name="password"
          id="password"
        />
      </div>
      <div class="pt-4">
        <label for="" class="medium">Confirm Password</label>
        <input
          class="w-100 custom-input input-height mt-2"
          placeholder="Type your Confirm password here"
          v-model="password_confirmation"
          type="password"
          name="password_confirmation"
          id="password_confirmation"
        />
      </div>

      <div class="pt-2">
        <VueRecaptcha
          ref="recaptcha"
          sitekey="6Lear4EpAAAAAMYrYF53yQMky3UapFUE1aoRF-F0"
          @verify="onCaptchaVerified"
        ></VueRecaptcha>
      </div>

      <div class="pt-2">
        <button
          @click="signupUser"
          class="hero-primary-btn d-flex align-items-center px-5 h-auto py-lg-3 py-sm-2 py-2 ms-auto w-100 justify-content-center bg-blue mt-3"
        >
          Signup
        </button>
      </div>
      <div class="error-message red-text mt-3 pt-2" v-html="errorMessage"></div>
      <div class="success-message green-text mt-3 pt-2" v-if="successMessage">
        {{ successMessage }}
      </div>
      <div
        class="text-center d-flex justify-content-center mt-4 flex-column wfc m-auto"
      >
        <!-- <button
          class="login-social-btn px-5 py-2 ml-5 mt-2 d-flex align-items-center justify-content-center"
          @click="AuthProvider('facebook')"
        >
          Continue with Facebook
          <img src="@/assets/images/facebook.png" alt="Apple Logo" />
        </button> -->
        <!-- <button
          class="login-social-btn px-5 py-2 ml-5 mt-2 d-flex align-items-center justify-content-center"
          @click="AuthProvider('google')"
        >
          Continue with Google
          <img src="@/assets/images/google.png" alt="Google Logo" />
        </button> -->

        <div class="pt-4 text-center medium">
          By continuing, you accept our terms and privacy policy.
        </div>
      </div>
      <div class="pt-2 text-center">
        Already have an account?
        <router-link to="/login" class="text-blue">Login</router-link>
      </div>
      <!-- Rest of your HTML code for social login buttons -->
    </div>
  </section>
</template>

<script>
import "bootstrap";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css"; // This imports Bootstrap JavaScript
import axios from "axios"; // Import Axios
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "SignupForm",
  components: { VueRecaptcha },
  data() {
    return {
      email: "",
      password: "",
      password_confirmation: "",
      successMessage: "",
      errorMessage: "",
      isGoogleCaptchaVerified: false,
    };
  },
  methods: {
    onCaptchaVerified() {
      this.isGoogleCaptchaVerified = true;
      // Handle verification logic here
    },
    AuthProvider(provider) {
      this.$auth
        .authenticate(provider)
        .then(() => {
          // self.SocialLogin(provider,response)
        })
        .catch((err) => {
          
        });
    },
    signupUser() {
      // Clear any previous error message
      this.errorMessage = "";

      if (
        this.email == "" ||
        this.password == "" ||
        this.password_confirmation == ""
      ) {
        toast("Please fill all the fields", {
          autoClose: 5000,
          position: "top-right",
          type: "error",
          closeOnClick: true,
          pauseOnHover: true,
          progressBar: true,
          timeout: 9000,
          showCloseButton: true,
          className: "custom-toast",
          html: true,
        });
        return false;
      } else if (!this.isGoogleCaptchaVerified) {
        toast("Please verify the captcha", {
          autoClose: 5000,
          position: "top-right",
          type: "error",
          closeOnClick: true,
          pauseOnHover: true,
          progressBar: true,
          timeout: 9000,
          showCloseButton: true,
          className: "custom-toast",
          html: true,
        });

        return false;
      }

      // Prepare the data to be sent in the request
      const requestData = {
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };

      // Make a POST request to your Laravel endpoint
      axios
        .post("https://admin1.jobcrafted.com/api/register", requestData)
        .then((response) => {
          
          
          // Check if the response has a 'success' attribute
          if (response.data.code == 200) {
            

            // Handle the successful response
            this.successMessage = response.data.message;
            // Assuming you have the API response stored in a variable called 'response'
            const plainTextToken = response.data.result.token.plainTextToken;

            // Store the token in local storage
            localStorage.setItem("accessToken", plainTextToken);
            // Clear form fields
            
            this.email = "";
            this.password = "";
            this.confirmPassword = "";
            localStorage.setItem("register_success", 1);
            // Redirect to the login page after successful signup
            this.$router.push("/login");
          } else {
            
            // Handle the response with an error message
            this.errorMessage = response.data.result[0];

            toast(this.errorMessage, {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
        });
    },
  },
};
</script>

<!-- Add your CSS styles here or link to an external CSS file if needed -->
<style scoped>
/* Your CSS styles go here */
@import "../assets/css/global.css";
/* Update the path as needed */
@import "bootstrap/dist/css/bootstrap.min.css";
</style>
