// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './components/route'; // Import the router
import Toastify from 'vue3-toastify';
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://admin1.jobcrafted.com/api/',
});

export default instance;

const app = createApp(App);
app.use(router); // Use Vue Router
app.use(Toastify);

app.mount('#app');
