
// src/router.js
import { createRouter, createWebHistory } from 'vue-router';

// Import your components (views) for routes
import Signup from './signUpForm.vue';
import Login from './loginForm.vue';
import Dashboard from './dashboardPage.vue';
import Forget from './forgetPassword.vue';
import Home from './HomePage.vue';
import PesonalSummary from './personalSummary.vue';
import ProfessionalSummary from './professionalSummary.vue';
import WorkExperience from './workExperience.vue';
import EducationSummary from './educationSummary.vue'
import SkillsSummary from './skillsSet.vue'
import ProjectsAchievements from './projectsAchievements.vue'
import AdditionalInfo from './additionalInfo.vue'
import ResumeReference from './referenceInfo.vue'
import FinalReview from './final-review.vue'
import StripePayment from './paymentPage.vue'
import HowToWrite from './howToWrite.vue'
import FAQPage from './faqPage.vue'
import ThankYou from './thankYou.vue'
import templatePage from './templatesPage.vue'
const routes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/signup',
    component: Signup,
  },
  {
    path: '/resume-templates',
    component: templatePage,
  },
  {
    path: '/how-to-write-resume',
    component: HowToWrite,
  },
  {
    path: '/payment-success',
    component: ThankYou,
  },
  {
    path: '/FAQ',
    component: FAQPage,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/build-resume-personal-summary',
    component: PesonalSummary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/build-resume-education-summary',
    component: EducationSummary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/build-resume-projects-achievements',
    component: ProjectsAchievements,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/build-resume-payment-page',
    component: StripePayment,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/build-resume-additional-info',
    component: AdditionalInfo,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/build-resume-skills-summary',
    component: SkillsSummary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/build-resume-reference',
    component: ResumeReference,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/build-resume-final-review',
    component: FinalReview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/build-resume-work-experience',
    component: WorkExperience,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/build-resume-professional-summary',
    component: ProfessionalSummary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/forget-password',
    component: Forget,
  },
  {
    path: '/',
    component: Home,
  },
  // Other routes
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
// ...

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const accessToken = localStorage.getItem('accessToken');
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    if (!accessToken || !tokenExpiration) {
      // No token or expiration information found, redirect to login
      next({ path: '/login' });
    } else {
      const currentTime = new Date().getTime();
      if (currentTime > tokenExpiration) {
        // Token has expired, remove it from local storage and redirect to login
        localStorage.removeItem('accessToken');
        localStorage.removeItem('tokenExpiration');
        next({ path: '/login' });
      } else {
        // Token is valid, continue with the route
        next();
      }
    }
  } else {
    // Routes that don't require authentication
    next();
  }
});

// ...


export default router;
