<template>
  <section>
    <AdminHeader />
    <div class="dashboard-main">
      <div class="d-flex mx-0">
        <div class="dashboard-main-left sticky-top bg-black h-100">
          <div>
            <img
              src="@/assets/images/white-logo.png"
              class="dashbaord-whitelogo"
              alt=""
            />
          </div>
          <div class="timeline-dashboard">
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 1 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Personal Information
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 2 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Professional Summary
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 3 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Work Experience
              </div>
            </div>

            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 4 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">Education</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 5 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">Skills</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 6 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Projects or Achievements
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 7 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Additional Information
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 8 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">References</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  9
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Final Review and Download
              </div>
            </div>
          </div>
          <!-- Links and copyright -->
          <div class="mb-3 mt-5 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Privacy Policy </span>
          </div>
          <div class="mb-3 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Terms & Conditions </span>
          </div>
          <div class="mb-3 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Contact Us </span>
          </div>
          <div class="text-white fs-12 mt-5 d-lg-block d-none d-sm-none">
            © 2023, Job Crafted. All rights reserved
          </div>
        </div>
        <div class="dashboard-main-right ps-lg-5 ps-sm-1 ps-1">
          <div class="row dashboard-row">
            <div class="col-lg-9 dashboard-mid-col">
              <div class="bg-transparent bg-none">
                <h4 class="text-black bold mb-0">Final Review and Download</h4>
                <p class="mb-0 text-black pt-2">
                  Please review your completed resume. Are there any edits or
                  adjustments needed?
                </p>
                <div class="text-blue pt-4">[Job_Crafted_Resume]</div>
              </div>
              <div class="mt-4 row col-lg-12 px-0">
                <div class="col-lg-5 sticky-top">
                  <div class="">
                    <label class="bold text-black mb-2"
                      >Experience Section</label
                    >
                    <textarea
                      name=""
                      @input="updateExperience(this)"
                      id="experienceText"
                      maxlength="50"
                      placeholder="Write Your Experience here..."
                      cols="12"
                      rows="8"
                      class="w-100 custom-input"
                    ></textarea>
                  </div>
                  <div class="pt-3 d-flex">
                    <button
                      class="btn-skills btn-editing bg-white btn fs-14 d-flex align-items-center justify-content-center w-50"
                    >
                      <img
                        src="@/assets/images//close-circle.png"
                        alt=""
                        srcset=""
                      />
                      &nbsp; Cancel
                    </button>
                    <div class="ps-3 w-50">
                      <button
                        class="btn-skills btn-editing bg-white btn fs-14 d-flex align-items-center justify-content-center w-100"
                      >
                        <img
                          src="@/assets/images//clipboard-tick.png"
                          alt=""
                          srcset=""
                        />
                        &nbsp; Save
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-lg-7" id="templateModal"></div>
              </div>
            </div>
            <div class="col-lg-3 ps-lg-4 ps-sm-3 ps-3 mt-lg-0 mt-sm-4 mt-4">
              <div class="p-3 bg-F1F5F9 rounded-10">
                <div class="pt-3">
                  <router-link to="build-resume-payment-page">
                    <button class="outline-btn-pdf-bg w-100">
                      <img
                        src="@/assets/images//document-download-white.png"
                        alt=""
                        srcset=""
                      />
                      &nbsp;
                      <span class="text-white medium">Download Resume</span>
                    </button>
                  </router-link>
                </div>
                <div class="pt-3">
                  <button
                    class="outline-btn-pdf-black w-100"
                    @click="emailResume"
                  >
                    <img src="@/assets/images//email-1.png" alt="" srcset="" />
                    &nbsp;
                    <span class="text-black medium">Email</span>
                  </button>
                </div>
                <div class="pt-3">
                  <button class="outline-btn-pdf-black w-100">
                    <img
                      src="@/assets/images//printer-1.png"
                      alt=""
                      srcset=""
                    />
                    &nbsp;
                    <span class="text-black medium">Print</span>
                  </button>
                </div>
              </div>
              <div class="accordion mt-4" id="accordionExampleZero">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button editCollapsebtn medium collapsed"
                      type="button"
                    >
                      <img
                        src="@/assets/images//message-edit.png"
                        alt=""
                        srcset=""
                      />
                      &nbsp; Edit Resume
                    </button>
                  </h2>
                  <div
                    id="collapseZero"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExampleZero"
                  >
                    <div class="accordion-body">
                      <div class="row small-image-pdf-row-1">
                        <div class="col-lg-6 col-sm-6 col-6 mb-3">
                          <img
                            src="@/assets/images//small-image-pdf.png"
                            class="w-100 small-image-pdf"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="col-lg-6 col-sm-6 col-6 mb-3">
                          <img
                            src="@/assets/images//small-image-pdf.png"
                            class="w-100 small-image-pdf activeSelect"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="col-lg-6 col-sm-6 col-6 mb-3">
                          <img
                            src="@/assets/images//small-image-pdf.png"
                            class="w-100 small-image-pdf"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="col-lg-6 col-sm-6 col-6 mb-3">
                          <img
                            src="@/assets/images//small-image-pdf.png"
                            class="w-100 small-image-pdf"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="col-lg-6 col-sm-6 col-6 mb-3">
                          <img
                            src="@/assets/images//small-image-pdf.png"
                            class="w-100 small-image-pdf"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="col-lg-6 col-sm-6 col-6 mb-3">
                          <img
                            src="@/assets/images//small-image-pdf.png"
                            class="w-100 small-image-pdf"
                            alt=""
                            srcset=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- s -->
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button medium collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <img
                        src="@/assets/images//document-text-document.png"
                        alt=""
                        srcset=""
                      />
                      &nbsp; Template
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row small-image-pdf-row-1">
                        <div
                          class="col-lg-6 col-sm-6 col-6 mb-3"
                          @click="changeTemplate('default')"
                        >
                          <img
                            src="@/assets/images/1.png"
                            class="default w-100 small-image-pdf"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div
                          class="col-lg-6 col-sm-6 col-6 mb-3"
                          @click="changeTemplate('template1')"
                        >
                          <img
                            src="@/assets/images/2.png"
                            class="template1 w-100 small-image-pdf activeSelect"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div
                          class="col-lg-6 col-sm-6 col-6 mb-3"
                          @click="changeTemplate('template2')"
                        >
                          <img
                            src="@/assets/images/3.png"
                            class="template2 w-100 small-image-pdf"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div
                          class="col-lg-6 col-sm-6 col-6 mb-3"
                          @click="changeTemplate('template3')"
                        >
                          <img
                            src="@/assets/images/4.png"
                            class="template3 w-100 small-image-pdf"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div
                          class="col-lg-6 col-sm-6 col-6 mb-3"
                          @click="changeTemplate('template4')"
                        >
                          <img
                            src="@/assets/images/5.png"
                            class="template4 w-100 small-image-pdf"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div
                          class="col-lg-6 col-sm-6 col-6 mb-3"
                          @click="changeTemplate('template5')"
                        >
                          <img
                            src="@/assets/images/6.png"
                            class="template4 w-100 small-image-pdf"
                            alt=""
                            srcset=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item border-bottom-0"
                  style="border-bottom: 0px !important"
                >
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed medium"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <img
                        src="@/assets/images//drop-blue.png"
                        alt=""
                        srcset=""
                      />
                      &nbsp; Colors
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body pt-0 mt-2">
                      <h6 class="medium text-black mb-2">Suggested Color</h6>
                      <div
                        class="d-flex justify-content-lg-between flex-wrap align-items-start"
                      >
                        <div class="px-1 mb-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="41"
                            height="41"
                            viewBox="0 0 44 44"
                            fill="none"
                          >
                            <rect
                              x="4"
                              y="4"
                              width="39.6667"
                              height="39.6667"
                              rx="3"
                              fill="#CBD5E1"
                            />
                            <path
                              d="M31 14L14.0294 30.9706"
                              stroke="white"
                              stroke-width="3"
                              stroke-linecap="round"
                            />
                          </svg>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('0063B2')"
                            style="border: 12px solid #0063b2"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('009473')"
                            style="border: 12px solid #009473"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('101010')"
                            style="border: 12px solid #101010"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('91B3FA')"
                            style="border: 12px solid #91b3fa"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('FF5722')"
                            style="border: 12px solid #ff5722"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('007D91')"
                            style="border: 12px solid #007d91"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('FF928E')"
                            style="border: 12px solid #ff928e"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('B13589')"
                            style="border: 12px solid #b13589"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('FB8A2E')"
                            style="border: 12px solid #fb8a2e"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('3F6889')"
                            style="border: 12px solid #3f6889"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('4C246D')"
                            style="border: 12px solid #4c246d"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('00ADEF')"
                            style="border: 12px solid #00adef"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('D93C65')"
                            style="border: 12px solid #d93c65"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('B07540')"
                            style="border: 12px solid #b07540"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('2F338E')"
                            style="border: 12px solid #2f338e"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('F23148')"
                            style="border: 12px solid #f23148"
                          ></div>
                        </div>
                        <div class="px-1 mb-2">
                          <div
                            class="color-square"
                            @click="changeColor('AD7A2C')"
                            style="border: 12px solid #ad7a2c"
                          ></div>
                        </div>
                      </div>
                      <h6 class="medium text-black mb-2 pt-2">Custom Color</h6>
                      <div class="input-group flex-nowrap mb-3">
                        <div class="input-group-prepend">
                          <div
                            class="custom-input input-height bg-light medium text-383838"
                          >
                            RGB
                          </div>
                        </div>
                        <input
                          type="text"
                          class="custom-input w-100 input-height text-383838"
                          id="rgb-color"
                          plaecholder="Write RGB here"
                          v-model="experience"
                        />
                        <div
                          class="color-square cursor-pointer input-border-color"
                          @click="applyRGB()"
                        ></div>
                        <input type="color" hidden name="" id="inputColor" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed medium"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <img
                        src="@/assets/images//smallcaps.png"
                        alt=""
                        srcset=""
                      />
                      &nbsp; Formatting Option
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row marginSmZero">
                        <div
                          class="col-lg-4 col-4 formatting-btn-col col-sm-4"
                          @click="changeFont('14', 'smallFont')"
                        >
                          <button class="formatting-btn w-100 smallFont">
                            <img
                              src="@/assets/images//small.png"
                              alt=""
                              srcset=""
                            />
                            <div class="pt-2 medium">Small</div>
                          </button>
                        </div>
                        <div
                          class="col-lg-4 col-4 formatting-btn-col col-sm-4"
                          @click="changeFont('16', 'mediumFont')"
                        >
                          <button
                            class="formatting-btn w-100 formatting-btn-active mediumFont"
                          >
                            <img
                              src="@/assets/images//normal.png"
                              alt=""
                              srcset=""
                            />
                            <div class="pt-2 medium">Normal</div>
                          </button>
                        </div>
                        <input type="hidden" name="template" id="template" />
                        <input type="hidden" name="color" id="color" />
                        <div
                          class="col-lg-4 col-4 formatting-btn-col col-sm-4"
                          @click="changeFont('19', 'largeFont')"
                        >
                          <button class="formatting-btn w-100 largeFont">
                            <img
                              src="@/assets/images//large.png"
                              alt=""
                              srcset=""
                            />
                            <div class="pt-2 medium">Large</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
      
<script>
import "bootstrap";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css"; // This imports Bootstrap JavaScript
import axios from "axios"; // Import Axios
import $ from "jquery";
import AdminHeader from "./AdminHeader.vue";

export default {
  components: {
    AdminHeader,
  },
  mounted() {
    const accessToken = localStorage.getItem("accessToken");
    const data = {
      professionalForm: {
        professionaSummary: "aaa",
        skills: "",
        access_token: "null",
      },
      workExperienceForm: {
        company_name: "Cricket",
        start_month: "March",
        start_year: "1944",
        end_month: "3",
        end_year: "1949",
        skills: "Marketing Specialist",
        access_token: "null",
      },
      educationFormData: {
        certification: "AWS Certified Solutions Architect – Associate",
        institute: "University of Oxford",
        graduate_date: "3",
        graduate_month: "May",
        graduate_year: "1945",
        skills: "",
        access_token: "null",
      },
      skillFormData: { skills: "as,as", access_token: "null" },
      projectsForm: {
        projectDescription: "as",
        projectOutcomes: "asa",
        access_token: "null",
      },
      additionalFormData: {
        selectedLanguage: "Chinese",
        professionalAffiliations: "NoAffiliation",
        volunteerWork: "no work",
        access_token: "null",
      },
      referenceFormData: {},
    };

    const jsonData = JSON.stringify(data);

    // Create a data object to send with the request
    const requestData = {
      accessToken: accessToken,
      formData: jsonData,
    };
    // Make a POST request to your API endpoint
    axios
      .post(
        "https://admin1.jobcrafted.com/api/get-reference-info-template",
        requestData
      )
      .then((response) => {
        $("#templateModal").html(response.data);
        // Handle a successful response here
        if (response.data) {
          this.fullName = response.data.fullName || "";
          this.professionalTitle = response.data.professionalTitle || "";
          this.company = response.data.company || "";
          this.email = response.data.email || "";
          this.countryCode = response.data.countryCode || "";
          this.phoneNumber = response.data.phoneNumber || "";
          $("#template").val("default");
          $("#color").val("0000");
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error sending data", error);
      });
  },
  data() {
    return {
      experience: "",
    };
  },
  name: "final-review",
  methods: {
    viewUserResume(templateName) {
      const formData = new FormData();
      const accessToken = localStorage.getItem("accessToken");
      formData.append("accessToken", accessToken);
      formData.append("template", templateName);
      axios
        .post(
          "https://admin1.jobcrafted.com/api/get-reference-info-template",
          formData
        )
        .then((response) => {
          $("#templateModal").html(response.data);
          // return;
          // Handle a successful response here
          if (response.data.code == 200) {
            toast("Data Saved Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            this.validationErrors = response.data.result;
            $("#errorDiv").append(`<p class="mb-0 text-red pt-2"></p>`);
            $("#errorDiv").append(
              `<p class="mb-0 text-red pt-2"> - ${this.validationErrors}</p>`
            );
            toast(this.validationErrors[0], {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);

          // Display an error message to the user if needed
        });
    },
    updateExperience() {
      var valueToUpdate = $("#experienceText").val();
      $(".experience-info").text(valueToUpdate);
    },
    applyRGB() {
      let color = $("#rgb-color").val();
      // Get all th elements and apply the color
      var headings = document.querySelectorAll("th");
      headings.forEach(function (heading) {
        heading.style.color = "#" + color;
      });
    },
    emailResume() {
      toast("Email has been sent Successfully", {
        autoClose: 5000,
        position: "top-right",
        type: "success",
        closeOnClick: true,
        pauseOnHover: true,
        progressBar: true,
        timeout: 9000,
        showCloseButton: true,
        className: "custom-toast",
        html: true,
      });
    },
    changeColor(color) {
      // Get all th elements and apply the color
      var headings = document.querySelectorAll("th");
      headings.forEach(function (heading) {
        heading.style.color = "#" + color;
      });
    },
    changeFont(size, className) {
      // var colorCode = response.data.colorCode;
      $(".formatting-btn").removeClass("formatting-btn-active");
      $("." + className).addClass("formatting-btn-active");
      // Get all th elements and apply the color
      var headings = document.querySelectorAll("tdh");
      var text = document.querySelectorAll("td");
      headings.forEach(function (heading) {
        size = parseInt(size) + 2;
        heading.style.fontSize = size + "px";
      });
      text.forEach(function (txt) {
        txt.style.fontSize = size + "px";
      });
    },
    changeTemplate(template) {
      $("#template").val(template);
      var colorToApply = $("#color").val();
      const accessToken = localStorage.getItem("accessToken");
      $(".small-image-pdf").removeClass("activeSelect");
      $("." + template).addClass("activeSelect");
      // Define the key-value pairs
      var formData = new FormData();
      const data = {
        professionalForm: {
          professionaSummary: "aaa",
          skills: "",
          access_token: "null",
        },
        workExperienceForm: {
          company_name: "Cricket",
          start_month: "March",
          start_year: "1944",
          end_month: "3",
          end_year: "1949",
          skills: "Marketing Specialist",
          access_token: "null",
        },
        educationFormData: {
          certification: "AWS Certified Solutions Architect – Associate",
          institute: "University of Oxford",
          graduate_date: "3",
          graduate_month: "May",
          graduate_year: "1945",
          skills: "",
          access_token: "null",
        },
        skillFormData: { skills: "as,as", access_token: "null" },
        projectsForm: {
          projectDescription: "as",
          projectOutcomes: "asa",
          access_token: "null",
        },
        additionalFormData: {
          selectedLanguage: "Chinese",
          professionalAffiliations: "NoAffiliation",
          volunteerWork: "no work",
          access_token: "null",
        },
        referenceFormData: {},
      };

      // Loop through the key-value pairs and append them to the FormData object
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          // Convert the object to a JSON string and append it to FormData
          formData.append(key, JSON.stringify(data[key]));
        }
      }
      // Create a data object to send with the request
      const requestData = {
        accessToken: accessToken,
        template: template,
        colorToApply: colorToApply,
        formData: formData,
      };
      axios
        .post(
          "https://admin1.jobcrafted.com/api/get-reference-info-template",
          requestData
        )
        .then((response) => {
          // Handle a successful response here
          if (response.data) {
            $("#templateModal").html(response.data);

            toast("Template Applied Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            toast("Template can be applied", {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
          // this.$router.push('/personal_summary');
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);
        });
    },
    // download pdf resume
    downloadUserResume(templateName) {
      const formData = new FormData();
      const accessToken = localStorage.getItem("accessToken");
      formData.append("access_token", accessToken);
      formData.append("templateName", templateName);
      axios
        .post(
          "https://admin1.jobcrafted.com/api/download-user-resume",
          formData
        )
        .then((response) => {
          // Handle a successful response here
          if (response.data.code == 200) {
            toast("Data Saved Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            this.validationErrors = response.data.result;
            $("#errorDiv").append(`<p class="mb-0 text-red pt-2"></p>`);
            $("#errorDiv").append(
              `<p class="mb-0 text-red pt-2"> - ${this.validationErrors}</p>`
            );
            toast(this.validationErrors[0], {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
          this.$router.push("/build-resume-payment-page");
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);

          // Display an error message to the user if needed
        });
    },
    saveReferenceInfoData() {
      // Get the form data
      const formData = new FormData(document.getElementById("referenceForm"));
      const accessToken = localStorage.getItem("accessToken");
      formData.append("access_token", accessToken);
      // Make a POST request to your API endpoint
      axios
        .post("https://admin1.jobcrafted.com/api/save-reference-info", formData)
        .then((response) => {
          // Handle a successful response here
          if (response.data.code == 200) {
            this.$router.push("/build-resume-final-review");
          } else {
            this.validationErrors = response.data.result;
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);

          // Display an error message to the user if needed
        });
    },
  },
};
</script>
      
<style scoped>
/* Your component-specific styles go here */
.validation-errors {
  color: red;
}
</style>
      
      <!-- Add your CSS styles here or link to an external CSS file if needed -->
<style scoped>
/* Import CSS */
@import "@/assets/css/global.css";
@import "@/assets/css/dashboard.css";

@import "bootstrap/dist/css/bootstrap.min.css";
</style>
      