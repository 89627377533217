<template>
  <section
    class="login-section h-100 d-flex align-items-center justify-content-center m-auto"
  >
    <div class="login-main position-relative">
      <div class="blue-circle-login"></div>
      <h2 class="bold text-center">Forget Password</h2>

      <div class="pt-4">
        <label for="" class="medium">Email Address</label>
        <input
          class="w-100 custom-input input-height mt-2"
          placeholder="Write associated Email here"
          v-model="email"
          type="email"
        />
      </div>

      <div class="pt-2">
        <button
          @click.prevent="sendPasswordRequest"
          class="hero-primary-btn d-flex align-items-center px-5 h-auto py-lg-3 py-sm-2 py-2 ms-auto wfc justify-content-center bg-blue mt-3"
        >
          Send Password
        </button>
      </div>

      <div class="d-flex align-items-center mt-3">
        <div class="w-50 pe-3 border-top"></div>
        <div class="wsnw px-4">
          <router-link to="/login" class="text-blue">Or Login</router-link>
        </div>
        <div class="w-50 ps-3 border-top"></div>
      </div>
    </div>
  </section>
</template>
  
<script>
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css"; // This imports Bootstrap JavaScript
export default {
  name: "ForgetPassword",
  data() {
    return {
      email: "",
      successMessage: "",
      errorMessage: "",
    };
  },
  methods: {
    sendPasswordRequest() {
      // Clear any previous messages
      this.successMessage = "";
      this.errorMessage = "";

      // Prepare the data to be sent in the request
      const requestData = {
        email: this.email, // Include the email input value
      };

      // Make a POST request to your Laravel endpoint for password reset
      axios
        .post("https://admin1.jobcrafted.com/api/forgot-password", requestData)
        .then((response) => {
          // Check if the response has a 'success' attribute
          if (response.data.code === 200) {
            // Handle the successful response
            this.errorMessage = response.data.result;
            toast(this.errorMessage, {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            // Handle the response with an error message

            this.errorMessage = response.data.result;
            Swal.fire({
              icon: "error",
              title: "Error Occured",
              text: this.errorMessage,
            });
          }
        })
        .catch((error) => {
          // Handle errors and display an error message
          this.errorMessage = error.response.data.result;
          Swal.fire({
            icon: "error",
            title: "Error Occured",
            text: this.errorMessage,
          });
          console.error(error);
        });
    },
  },
};
</script>
  
  <!-- Add your CSS styles here or link to an external CSS file if needed -->
<style scoped>
/* Your CSS styles go here */
@import "../assets/css/global.css";
/* Update the path as needed */
@import "bootstrap/dist/css/bootstrap.min.css";
</style>
  