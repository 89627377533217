<template>
  <section
    class="login-section d-flex align-items-center justify-content-center m-auto"
  >
    <div class="login-main position-relative">
      <div class="blue-circle-login"></div>
      <h2 class="bold text-center">Login</h2>

      <div class="pt-4">
        <label for="email" class="medium">Email Address</label>
        <input
          class="w-100 custom-input input-height mt-2"
          placeholder="Type your email address here"
          v-model="email"
          type="email"
          name="email"
          id="email"
        />
      </div>
      <div class="pt-4">
        <label for="password" class="medium">Password</label>
        <input
          class="w-100 custom-input input-height mt-2"
          placeholder="Type your password here"
          v-model="password"
          type="password"
          name="password"
          id="password"
        />
      </div>
      <div class="pt-2">
        <router-link to="/forget-password" class="forget_password"
          >Forget password?
        </router-link>
      </div>
      <div class="pt-2">
        <button
          @click="loginUser"
          class="hero-primary-btn d-flex align-items-center px-5 h-auto py-lg-3 py-sm-2 py-2 ms-auto w-100 justify-content-center bg-blue mt-3"
        >
          Login
        </button>
      </div>
      <div class="error-message red-text mt-3 pt-2" v-html="errorMessage"></div>
      <div class="success-message green-text mt-3 pt-2" v-if="successMessage">
        {{ successMessage }}
      </div>

      <div class="pt-2 text-center">
        Don't have an account?
        <router-link to="/signup" class="text-blue">Sign Up</router-link>
      </div>
    </div>
  </section>
</template>
  
<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css"; // This imports Bootstrap JavaScript
export default {
  name: "loginForm",
  data() {
    return {
      email: "",
      password: "",
      successMessage: "",
      errorMessage: "",
    };
  },
  created() {
    if (
      localStorage.getItem("register_success") &&
      localStorage.getItem("register_success") == 1
    ) {
      toast("Welcome to Job Crafted", {
        autoClose: 5000,
        position: "top-right",
        type: "success",
        closeOnClick: true,
        pauseOnHover: true,
        progressBar: true,
        timeout: 9000,
        showCloseButton: true,
        className: "custom-toast",
        html: true,
        onClose: function () {
          localStorage.removeItem("register_success");
        },
      });
    }
  },
  methods: {
    loginUser() {
      // Clear any previous error or success messages
      this.errorMessage = "";
      this.successMessage = "";

      // Prepare the data to be sent in the request
      const requestData = {
        email: this.email,
        password: this.password,
      };

      // Make a POST request to your Laravel login endpoint
      axios
        .post("https://admin1.jobcrafted.com/api/login", requestData)
        .then((response) => {
          // Check if the response has a 'success' attribute
          if (response.data.code == 200) {
            // Handle the successful response
            this.successMessage = response.data.message;

            localStorage.setItem("login_success", 1);

            const plainTextToken = response.data.result.token.plainTextToken;

            // Calculate the token expiration time (2 hours in milliseconds)
            const expirationTime = new Date().getTime() + 2 * 60 * 60 * 1000;

            // Store the token and its expiration time in local storage
            localStorage.setItem("accessToken", plainTextToken);
            localStorage.setItem("tokenExpiration", expirationTime);

            // Clear form fields
            this.email = "";
            this.password = "";

            // Redirect to the login page after successful login
            this.$router.push("/dashboard");

            // Set a timeout to remove the token from local storage after 2 hours
            setTimeout(() => {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("tokenExpiration");
            }, 2 * 60 * 60 * 1000); // 2 hours in milliseconds
          } else {
            // Handle the response with an error message
            this.errorMessage = response.data.result;
            toast(this.errorMessage, {
              type: "error",
            });
          }
        });
    },
  },
};
</script>
  
<style scoped>
/* Your CSS styles for the Login component go here */
</style>
  