<template>
  <section>
    <AdminHeader />
    <div class="dashboard-mainpadding">
      <div class="dashboard-mid">
        <h4 class="text-white bold mb-0">Welcome Back,</h4>
        <p class="mb-0 text-white pt-2 fs-30 bold">
          Explore your complete
          <span class="text-red bold">Resume Collection</span> here
        </p>
      </div>
    </div>
    <div class="dashboard-mainpadding mb-5">
      <ul
        class="nav nav-pills mb-0 nav-pills-tabs"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Dashboard
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Resumes
          </button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabindex="0"
        >
          <div class="latest-resumes">
            <div class="row marginSmZero">
              <div class="col-lg-5 paddingSmZero">
                <h5 class="text-black bold pb-3">Latest Resumes</h5>
                <div class="row marginSmZero">
                  <!-- Resume Card 1 -->
                  <div class="col-lg-6 mb-lg-0 mb-sm-4 mb-4 paddingSmZero">
                    <div class="overlay-img-main">
                      <!-- Resume Image -->
                      <div class="position-relative">
                        <div class="overlay-img-1">
                          <!-- Edit Button -->
                          <router-link to="/build-resume-personal-summary">
                            <button
                              class="btn btn-light template-btn dashboard-template-btn px-3 py-2 medium d-flex align-items-center"
                            >
                              <img
                                class="px-1"
                                src="@/assets/images/mdo-edit.png"
                                alt=""
                              />Edit
                            </button>
                          </router-link>
                          <div
                            class="d-flex justify-content-between overlay-img-div"
                          >
                            <!-- Download and Print Buttons -->
                            <router-link to="/build-resume-final-review">
                              <button class="btn">
                                <img
                                  src="@/assets/images/mbi-download.png"
                                  alt=""
                                />
                              </button>
                            </router-link>

                            <router-link to="/build-resume-final-review">
                              <button class="btn">
                                <img
                                  src="@/assets/images/mbi-print.png"
                                  alt=""
                                />
                              </button>
                            </router-link>
                          </div>
                        </div>
                        <img src="@/assets/images/1.png" class="w-100" alt="" />
                      </div>
                      <!-- Resume Information -->
                      <h6 class="bold h5 mb-0 pt-3 text-center">
                        Hamza’s Resume
                      </h6>
                      <p class="text-center mb-0 pt-1">
                        July 12, 2023 (3 days ago)
                      </p>
                    </div>
                  </div>
                  <!-- Resume Card 2 -->
                </div>
              </div>
              <div class="col-lg-7 paddingSmZero">
                <router-link to="/build-resume-personal-summary">
                  <div
                    class="resumeUpload resume-uploads col-md-5 float-end"
                    style="min-height: calc(40% - 70px)"
                  >
                    <div class="text-center">
                      <img src="@/assets/images/plus-image.png" alt="" />
                    </div>
                    <h5 class="bold h5 mb-0 pt-3 text-blue text-center">
                      Build a new Resume
                    </h5>
                    <p class="text-blue mb-0 text-center pt-3">
                      We recommend creating a custom resume for each job
                      application
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Resumes Tab -->
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabindex="0"
        >
          <div class="latest-resumes">
            <div class="row marginSmZero">
              <div class="col-lg-9 paddingSmZero">
                <div class="row marginSmZero">
                  <!-- Resume Card 1 (Similar structure as Resume Card 1 in Dashboard Tab) -->
                  <div class="col-lg-4 paddingSmZero mb-lg-0 mb-sm-4 mb-4">
                    <!-- ... (Similar structure as Resume Card 1) -->
                  </div>
                  <!-- Resume Card 2 (Similar structure as Resume Card 1 in Dashboard Tab) -->
                  <div class="col-lg-4 paddingSmZero mb-lg-0 mb-sm-4 mb-4">
                    <!-- ... (Similar structure as Resume Card 1) -->
                  </div>
                  <!-- Resume Card 3 (Similar structure as Resume Card 1 in Dashboard Tab) -->
                  <div class="col-lg-4 paddingSmZero mb-lg-0 mb-sm-4 mb-4">
                    <!-- ... (Similar structure as Resume Card 1) -->
                  </div>
                </div>
              </div>
              <div class="col-lg-3 paddingSmZero">
                <router-link to="/build-resume-personal-summary">
                  <div class="resumeUpload resume-uploads">
                    <div class="text-center">
                      <img src="@/assets/images/plus-image.png" alt="" />
                    </div>
                    <h5 class="bold h5 mb-0 pt-3 text-blue text-center">
                      Build a new Resume
                    </h5>
                    <p class="text-blue mb-0 text-center pt-3">
                      We recommend creating a custom resume for each job
                      application
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import AdminHeader from "./AdminHeader.vue";

export default {
  name: "DashboardPage",
  components: {
    AdminHeader,
  },
  data() {
    return {
      email: "",
      password: "",
      successMessage: "",
      errorMessage: "",
      isLoggedIn: false, // You can initialize this based on the presence of accessToken in storage
    };
  },
  methods: {
    logout() {
      // Delete the accessToken key from storage
      localStorage.removeItem("accessToken"); // Replace with your storage mechanism
      localStorage.removeItem("tokenExpiration"); // Replace with your storage mechanism

      // Redirect to the login page
      this.$router.push("/login");
    },
  },
  created() {
    // Check if the user is already logged in based on the presence of accessToken in storage
    const accessToken = localStorage.getItem("accessToken"); // Replace with your storage mechanism

    if (accessToken) {
      this.isLoggedIn = true;
    }

    if (
      localStorage.getItem("login_success") &&
      localStorage.getItem("login_success") == 1
    ) {
      toast("Logged In Successfully", {
        autoClose: 5000,
        position: "top-right",
        type: "success",
        closeOnClick: true,
        pauseOnHover: true,
        progressBar: true,
        timeout: 9000,
        showCloseButton: true,
        className: "custom-toast",
        html: true,
        onClose: function () {
          localStorage.removeItem("login_success");
        },
      });
    }
  },
  computed: {
    hasAccessToken() {
      // Check if "accessToken" exists in storage and return true or false accordingly
      const accessToken = localStorage.getItem("accessToken"); // You can replace 'localStorage' with 'sessionStorage' if needed
      return !!accessToken;
    },
  },
};
</script>
  
<style scoped>
/* Your CSS styles for the Login component go here */
.dashboard-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Make it full height of the viewport */
  font-size: 2rem;
  /* Adjust the font size as needed */
  text-align: center;
}

.dashboard-message h1 {
  font-size: inherit;
  /* Inherit the font size from the parent */
}

@import "../assets/css/dashboard.css";
</style>
  