<template>
  <section>
    <AdminHeader />
    <div class="dashboard-main">
      <div class="d-flex mx-0">
        <div class="dashboard-main-left sticky-top bg-black h-100">
          <div>
            <img
              src="@/assets/images/white-logo.png"
              class="dashbaord-whitelogo"
              alt=""
            />
          </div>
          <div class="timeline-dashboard">
            <div
              class="d-flex align-items-center timeline-dashboard-1 .active-timelin position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 1 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Personal Information
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 2 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Professional Summary
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 3 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Work Experience
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 4 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">Education</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 5 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">Skills</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 6 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Projects or Achievements
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 7 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Additional Information
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 8 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">References</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  9
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Final Review and Download
              </div>
            </div>
          </div>
          <!-- Links and copyright -->
          <div class="mb-3 mt-5 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Privacy Policy </span>
          </div>
          <div class="mb-3 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Terms & Conditions </span>
          </div>
          <div class="mb-3 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Contact Us </span>
          </div>
          <div class="text-white fs-12 mt-5 d-lg-block d-none d-sm-none">
            © 2023, Job Crafted. All rights reserved
          </div>
        </div>
        <div class="dashboard-main-right ps-lg-5 ps-sm-1 ps-1">
          <div class="row dashboard-row">
            <div class="col-lg-9 dashboard-mid-col">
              <form action="" method="post" id="personalForm">
                <div class="dashboard-mid">
                  <h4 class="text-white bold mb-0">Personal Information</h4>
                  <p class="mb-0 text-white pt-2">
                    Please provide the following details to complete your
                    personal information for your resume
                  </p>
                </div>
                <!-- <div
                  class="validation-errors card mt-3 p-3"
                  v-if="validationErrors.length > 0"
                >
                  <ul>
                    <li v-for="error in validationErrors" :key="error">
                      - {{ error }}*
                    </li>
                  </ul>
                </div> -->

                <div class="mt-4">
                  <label class="bold text-black mb-2">What is your Name?</label>
                  <input
                    class="w-100 custom-input input-height"
                    name="name"
                    id="name"
                    placeholder="Enter your full name"
                    type="text"
                    v-model="personalInformation.name"
                  />
                </div>
                <div class="mt-4 row marginSmZero">
                  <div class="col-lg-6 paddingSmZero">
                    <label class="bold text-black mb-2"
                      >What is your contact information?</label
                    >
                    <input
                      class="w-100 custom-input input-height"
                      name="phone"
                      id="phone"
                      placeholder="Enter your phone number"
                      type="text"
                      v-model="personalInformation.contact"
                    />
                  </div>
                  <div class="col-lg-6 mt-lg-0 mt-sm-4 mt-4 paddingSmZero">
                    <label class="bold text-black mb-2">Enter your Email</label>
                    <input
                      class="w-100 custom-input input-height"
                      name="email"
                      id="email"
                      placeholder="Enter your Email here"
                      type="text"
                      v-model="personalInformation.email"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label class="bold text-black mb-2"
                    >What is your professional title or headline?</label
                  >
                  <div class="d-flex flex-wrap ml-mr">
                    <div class="d-flex flex-wrap ml-mr">
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Marketing Specialist"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Marketing Specialist
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="MSoftware Engineer"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          MSoftware Engineer
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Graphic Designer"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Graphic Designer
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Accountant"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Accountant
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Accountant"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Accountant
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Financial Analyst"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Financial Analyst
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Human Resources Manager"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Human Resources Manager
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Business Analyst"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Business Analyst
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Sales Representative"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Sales Representative
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Web Developer"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Web Developer
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Data Scientist"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Data Scientist
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Registered Nurse"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Registered Nurse
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Content Writer"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Content Writer
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="UX/UI Designer"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          UX/UI Designer
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Business Analyst"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Business Analyst
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Product Manager"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Product Manager
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Marketing Manager"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Marketing Manager
                        </button>
                      </div>
                      <div class="pl-pr mb-3">
                        <button
                          data-text="Other"
                          class="btn-skills btn fs-14"
                          type="button"
                          @click="toggleSkill"
                        >
                          Other
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <button
                      type="button"
                      @click="savePersonalInforData"
                      class="border-0 py-2 px-4 text-white btn-next px-4"
                    >
                      Next Step
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-3 ps-lg-4 ps-sm-3 ps-3 mt-lg-0 mt-sm-4 mt-4">
              <img src="" class="w-100" alt="" srcset="" />
              <div class="pt-3">
                <button
                  class="outline-btn-pdf w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <img src="@/assets/images/eye.png" alt="" srcset="" /> &nbsp;
                  <span
                    class="text-009473 medium"
                    @click="viewUserResume('default')"
                    >View Resume</span
                  >
                </button>
              </div>
              <div class="pt-3">
                <button
                  class="outline-btn-pdf-black w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                >
                  <img
                    src="@/assets/images/refresh-circle.png"
                    alt=""
                    srcset=""
                  />
                  &nbsp;
                  <span class="text-black medium">Apply Template</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <selectTemplateModal />
  </section>
</template>
    

<script>
import "bootstrap";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css"; // This imports Bootstrap JavaScript
import axios from "axios"; // Import Axios
import $ from "jquery";
import AdminHeader from "./AdminHeader.vue";
import selectTemplateModal from "./selectTemplateModal.vue";

var selectedSkills = [];
export default {
  components: {
    AdminHeader,
    selectTemplateModal,
  },
  mounted() {
    const accessToken = localStorage.getItem("accessToken");

    // Create a data object to send with the request
    const requestData = {
      accessToken: accessToken,
    };
    // Make a POST request to your API endpoint
    axios
      .post("https://admin1.jobcrafted.com/api/get-personal-info", requestData)
      .then((response) => {
        // Handle a successful response here
        if (response.data == "temp") {
          // Get the saved form data from localStorage
          const savedFormData = localStorage.getItem("personalFormData");

          // Parse the saved JSON data into a JavaScript object
          const formDataObject = JSON.parse(savedFormData);

          // Define a response.data object with the desired properties and values
          response.data = formDataObject;
        }

        if (response.data) {
          const email = response.data.email ? response.data.email : "";
          const phone = response.data.phone ? response.data.phone : "";
          const fullName = response.data.name ? response.data.name : "";
          const skills = response.data.skills ? response.data.skills : "";
          const skillsArray = skills.split(",");
          $("#name").val(fullName);
          $("#phone").val(phone);
          $("#email").val(email);
          // Iterate through each button with the class 'btn-skills'
          $(".btn-skills").each(function () {
            // Get the 'data-text' attribute value of the button
            const buttonText = $(this).data("text");

            // Check if the buttonText exists in the skillsArray
            if (skillsArray.includes(buttonText)) {
              // Add a class to indicate that the button is selected
              $(this).addClass("selected");
              $(this).css({
                backgroundColor: "#000",
                color: "#fff",
              });
              selectedSkills.push(buttonText);
            }
          });
          // Define a function to handle button click event (optional)
          $(".btn-skills").on("click", function () {
            // Toggle the 'selected' class on button click
            // $(this).toggleClass("selected");
            // If the button is selected, apply additional properties
            if ($(this).hasClass("selected")) {
              $(this).css({
                backgroundColor: "#000",
                color: "#fff",
              });
            } else {
              // If the button is deselected, reset properties
              $(this).css({
                backgroundColor: "",
                color: "",
              });
            }
          });
          toast("Data Syncing in Progress", {
            autoClose: 5000,
            position: "top-right",
            type: "success",
            closeOnClick: true,
            pauseOnHover: true,
            progressBar: true,
            timeout: 9000,
            showCloseButton: true,
            className: "custom-toast",
            html: true,
          });
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error sending data", error);
      });
  },
  name: "personalSummary",
  methods: {
    toggleSkill(event) {
      const button = event.target; // Get the clicked button element
      const skill = button.textContent.trim();

      // Check if the skill is already in the array
      var index = selectedSkills.indexOf(skill);

      if (index === -1) {
        // Skill is not in the array, so add it and add the selected class
        selectedSkills.push(skill);
        button.classList.add("selected");
        button.style.backgroundColor = "#000"; // Set background color to black
        button.style.color = "#fff"; // Set text color to white
      } else {
        // Skill is in the array, so remove it and remove the selected class
        selectedSkills.splice(index, 1);
        button.classList.remove("selected");
        button.style.backgroundColor = "rgba(56, 56, 56, 0.05)"; // Set background color back to the original
        button.style.color = ""; // Reset text color
      }

      // You can log or perform any other actions with the updated array
    },
    viewUserResume(templateName) {
      const formData = new FormData();
      const accessToken = localStorage.getItem("accessToken");
      formData.append("accessToken", accessToken);
      formData.append("template", templateName);
      axios
        .post(
          "https://admin1.jobcrafted.com/api/get-reference-info-template",
          formData
        )
        .then((response) => {
          $("#templateModal").html(response.data);
          // return;
          // Handle a successful response here
          if (response.data.code == 200) {
            toast("Data Saved Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            this.validationErrors = response.data.result;
            $("#errorDiv").append(`<p class="mb-0 text-red pt-2"></p>`);
            $("#errorDiv").append(
              `<p class="mb-0 text-red pt-2"> - ${this.validationErrors}</p>`
            );
            toast(this.validationErrors[0], {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);

          // Display an error message to the user if needed
        });
    },
    savePersonalInforData() {
      
      const accessToken = localStorage.getItem("accessToken");
      const formData = new FormData(document.getElementById("personalForm"));
      formData.append("access_token", accessToken);
      formData.append("skills", selectedSkills);
      
      axios
        .post("https://admin1.jobcrafted.com/api/save-personal-info", formData)
        .then((response) => {
          if (response.data.code == 200) {
            if (response.data.result === "temp") {
              const personalForm = document.getElementById("personalForm");
              const formData = new FormData(personalForm);
              formData.append("skills", selectedSkills);
              const accessToken = localStorage.getItem("accessToken");
              formData.append("access_token", accessToken);
              localStorage.setItem(
                "personalFormData",
                JSON.stringify(Object.fromEntries(formData.entries()))
              );
            }
            toast("Data Saved Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
            this.$router.push("/build-resume-professional-summary");
          } else {
            this.validationErrors = response.data.result;
            $("#errorDiv").append(`<p class="mb-0 text-red pt-2"></p>`);
            $("#errorDiv").append(
              `<p class="mb-0 text-red pt-2"> - ${this.validationErrors}</p>`
            );
            toast(this.validationErrors[0], {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
        })
        .catch((error) => {
          console.error("Error sending data", error);
        });
    },
    changeTemplate(template) {
      $("#template").val(template);
      var colorToApply = $("#color").val();
      const accessToken = localStorage.getItem("accessToken");
      $(".small-image-pdf").removeClass("activeSelect");
      $("." + template).addClass("activeSelect");
      // Define the key-value pairs
      var formData = new FormData();
      const data = {
        professionalForm: {
          professionaSummary: "aaa",
          skills: "",
          access_token: "null",
        },
        workExperienceForm: {
          company_name: "Cricket",
          start_month: "March",
          start_year: "1944",
          end_month: "3",
          end_year: "1949",
          skills: "Marketing Specialist",
          access_token: "null",
        },
        educationFormData: {
          certification: "AWS Certified Solutions Architect – Associate",
          institute: "University of Oxford",
          graduate_date: "3",
          graduate_month: "May",
          graduate_year: "1945",
          skills: "",
          access_token: "null",
        },
        skillFormData: { skills: "as,as", access_token: "null" },
        projectsForm: {
          projectDescription: "as",
          projectOutcomes: "asa",
          access_token: "null",
        },
        additionalFormData: {
          selectedLanguage: "Chinese",
          professionalAffiliations: "NoAffiliation",
          volunteerWork: "no work",
          access_token: "null",
        },
        referenceFormData: {},
      };

      // Loop through the key-value pairs and append them to the FormData object
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          // Convert the object to a JSON string and append it to FormData
          formData.append(key, JSON.stringify(data[key]));
        }
      }
      // Create a data object to send with the request
      const requestData = {
        accessToken: accessToken,
        template: template,
        colorToApply: colorToApply,
        formData: data,
      };
      axios
        .post(
          "https://admin1.jobcrafted.com/api/get-reference-info-template",
          requestData
        )
        .then((response) => {
          // Handle a successful response here
          if (response.data) {
            $("#templateModal").html(response.data);
            $("#templateModalChoose").html(response.data);

            toast("Template Applied Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            toast("Template can be applied", {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
          // this.$router.push('/personal_summary');
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);
        });
    },
  },
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      validationErrors: [],
      personalInformation: {
        name: "",
        email: "",
        contact: "",
      },
    };
  },
};
</script>

<style scoped>
/* Your component-specific styles go here */
.validation-errors {
  color: red;
}
</style>

  
    
    <!-- Add your CSS styles here or link to an external CSS file if needed -->
<style scoped>
/* Import CSS */
@import "../assets/css/global.css";
@import "../assets/css/dashboard.css";

@import "bootstrap/dist/css/bootstrap.min.css";
</style>
    