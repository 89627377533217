<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <router-link to="/" class="nav-logo js-anchor-link navbar-brand">
        <img src="@/assets/images/logo.png" alt="" srcset="" />
      </router-link>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon hamburger">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item" :class="{ activeMenu: $route.path === '/' }">
            <router-link
              to="/"
              class="nav-link px-3 medium js-anchor-link text-dark"
              >Home</router-link
            >
          </li>
          <li
            class="nav-item"
            :class="{ activeMenu: $route.path === '/resume-templates' }"
          >
            <router-link
              to="/resume-templates"
              class="nav-link px-3 medium js-anchor-link text-dark"
              >Templates</router-link
            >
          </li>
          <li
            class="nav-item"
            :class="{ activeMenu: $route.path === '/how-to-write-resume' }"
          >
            <router-link
              to="/how-to-write-resume"
              class="nav-link px-3 medium js-anchor-link text-dark"
              >How to Write</router-link
            >
          </li>
          <li class="nav-item" :class="{ activeMenu: $route.path === '/FAQ' }">
            <router-link
              to="/FAQ"
              class="nav-link px-3 medium js-anchor-link text-dark"
              >FAQs</router-link
            >
          </li>

          <li v-if="hasAccessToken">
            <div class="dropdown-center ms-3">
              <button
                class="border-0 bg-transparent dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="@/assets/images/user-admin.png" alt="" />
              </button>
              <ul class="dropdown-menu">
                <li>
                  <router-link to="/dashboard">
                    <a class="dropdown-item"> Dahsboard </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/login">
                    <a class="dropdown-item" @click="logout"> Logout </a>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-else>
            <router-link to="/login">
              <button
                class="hero-primary-btn d-flex align-items-center px-5 h-auto py-2 ms-auto w-100 justify-content-center bg-blue"
              >
                Login
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import "bootstrap";

export default {
  computed: {
    hasAccessToken() {
      const accessToken = localStorage.getItem("accessToken");
      return !!accessToken;
    },
  },

  methods: {
    logout() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("tokenExpiration");
      this.$router.push("/login");
    },
  },
};
</script>