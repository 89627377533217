<template>
    <div>
        <div class="grad-header"></div>
        <!-- <header class="header px-lg-0 px-3 px-sm-3">
            <nav class="navbar">
                <router-link to="/" class="nav-logo js-anchor-link">
                    <img src="@/assets/images/logo.png" alt="" srcset="" />
                </router-link>
                <ul class="nav-menu mb-0 phs-0">

                    <li class="nav-item">
                        <router-link to="/" class="nav-link px-3 medium js-anchor-link ">Home</router-link>
                    </li>
                    <li class="nav-item activeMenu">
                        <router-link to="/resume-templates"
                            class="nav-link px-3 medium js-anchor-link ">Templates</router-link>

                    </li>
                    <li class="nav-item">
                        <router-link to="/how-to-write-resume" class="nav-link px-3 medium js-anchor-link">How to
                            Write</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/FAQ" class="nav-link px-3 medium js-anchor-link">FAQs</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/login">
                            <button
                                class="hero-primary-btn d-flex align-items-center px-5 h-auto py-sm-2 ms-auto w-100 justify-content-center bg-blue">
                                Login
                            </button>
                        </router-link>
                    </li>
                </ul>
                <div class="hamburger">
                    <span class="bar"></span>
                    <span class="bar"></span>
                    <span class="bar"></span>
                </div>
            </nav>
        </header> -->

        <!-- navbar -->

        <FrontendHeader />

        <section class="mainmargintop">
            <div class="custom-container px-lg-5 px-sm-3 px-3 m-auto">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <h1 class="hero-title extrabold ttu py-3 mb-0">
                            Choose Your Perfect <br> <span class="text-red extrabold">Resume</span> Template
                        </h1>
                        <p class="fs-18 mb-0">
                            Discover the perfect resume template from our diverse collection. Whether you prefer a modern or
                            elegant
                            design, our curated selection ensures your resume stands out.
                        </p>
                        <div>
                            <router-link to="/build-resume-personal-summary">
                                <button
                                    class="hero-primary-btn d-flex align-items-center justify-content-center bg-blue mt-3">
                                    Build Resume
                                </button>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-lg-0 mt-sm-4 mt-4">
                        <img src="@/assets/images/template-hero.png" class="w-100" alt="" srcset="">
                    </div>
                </div>
            </div>
        </section>
        <section class="mainmargintop">
            <div class="custom-container px-lg-5 px-sm-3 px-3 m-auto">
                <div class="tamplate-tabs">
                    <ul class="nav nav-pills mb-3 template-nav-tabs" id="pills-tab" role="tablist">
                        <li class="nav-item " role="presentation">
                            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                aria-selected="true">Professional Experience</button>
                        </li>
                        <li class="nav-item ps-2" role="presentation">
                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                aria-selected="false">Education</button>
                        </li>
                        <li class="nav-item ps-2" role="presentation">
                            <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                                aria-selected="false">Skills</button>
                        </li>
                        <li class="nav-item ps-2" role="presentation">
                            <button class="nav-link" id="pills-disabled-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-disabled" type="button" role="tab" aria-controls="pills-disabled"
                                aria-selected="false">Projects</button>
                        </li>
                        <li class="nav-item ps-2" role="presentation">
                            <button class="nav-link" id="pills-disabled-tab1" data-bs-toggle="pill"
                                data-bs-target="#pills-disabled1" type="button" role="tab" aria-controls="pills-disabled1"
                                aria-selected="false">Certifications</button>
                        </li>
                        <li class="nav-item ps-2" role="presentation">
                            <button class="nav-link" id="pills-disabled-tab3" data-bs-toggle="pill"
                                data-bs-target="#pills-disabled3" type="button" role="tab" aria-controls="pills-disabled3"
                                aria-selected="false">Volunteer Work</button>
                        </li>
                        <li class="nav-item ps-2" role="presentation">
                            <button class="nav-link" id="pills-disabled-tab4" data-bs-toggle="pill"
                                data-bs-target="#pills-disabled4" type="button" role="tab" aria-controls="pills-disabled4"
                                aria-selected="false">References</button>
                        </li>
                    </ul>
                    <div class="tab-content mt-4" id="pills-tabContent">
                        <div class="tab-pane fade show active " id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab" tabindex="0">
                            <div class="row">
                                <div class="col-lg-3 mb-3 col-md-6 col-sm-12 col-12 border ml-2 box-shadow">
                                    <div class="template-hover">
                                        <div class="position-relative">
                                            <div class="overlay-img">
                                                <router-link to="/build-resume-personal-summary">

                                                    <button class="btn btn-light template-btn px-4 py-2 medium">Use This
                                                        Templete</button>
                                                </router-link>
                                            </div>
                                            <img src="@/assets/images/1.png" class="w-100" alt="" srcset="">
                                        </div>
                                        <div class="d-flex pt-3">
                                            <div id="color-template">
                                                <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                    class=" cursor-pointer active-template" height="21" viewBox="0 0 21 21"
                                                    fill="none">
                                                    <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                        fill="#009473" />
                                                    <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                                </svg>
                                            </div>
                                            <div class="ms-1" id="color-template">
                                                <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                    class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                    <rect x="0.0209961" y="0.814941" width="20" height="21" rx="3"
                                                        fill="#662D50" />
                                                    <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                                </svg>
                                            </div>
                                            <div class="ms-1" id="color-template">

                                                <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                    class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                    <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                        fill="#FF4F58" />
                                                    <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                                </svg>
                                            </div>
                                            <div class="ms-1" id="color-template">
                                                <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                    class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                    <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                        fill="#0063B2" />
                                                    <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="bold fs-18 pt-2 text-black">Traditional</div>
                                        <p class="fs-14 pt-0">
                                            A modern layout with a contrasting sidebar that catches the eye.
                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"
                            tabindex="0">
                            <div class="col-lg-3 mb-3 col-md-6 col-sm-12 col-12 border ml-2 box-shadow">
                                <div class="template-hover">
                                    <div class="position-relative">
                                        <div class="overlay-img">
                                            <router-link to="/build-resume-personal-summary">

                                                <button class="btn btn-light template-btn px-4 py-2 medium">Use This
                                                    Templete</button>
                                            </router-link>
                                        </div>
                                        <img src="@/assets/images/1.png" class="w-100" alt="" srcset="">
                                    </div>
                                    <div class="d-flex pt-3">
                                        <div id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer active-template" height="21" viewBox="0 0 21 21"
                                                fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#009473" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="21" rx="3"
                                                    fill="#662D50" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">

                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#FF4F58" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#0063B2" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="bold fs-18 pt-2 text-black">Traditional</div>
                                    <p class="fs-14 pt-0">
                                        A modern layout with a contrasting sidebar that catches the eye.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"
                            tabindex="0">
                            <div class="col-lg-3 mb-3 col-md-6 col-sm-12 col-12 border ml-2 box-shadow">
                                <div class="template-hover">
                                    <div class="position-relative">
                                        <div class="overlay-img">
                                            <router-link to="/build-resume-personal-summary">

                                                <button class="btn btn-light template-btn px-4 py-2 medium">Use This
                                                    Templete</button>
                                            </router-link>
                                        </div>
                                        <img src="@/assets/images/1.png" class="w-100" alt="" srcset="">
                                    </div>
                                    <div class="d-flex pt-3">
                                        <div id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer active-template" height="21" viewBox="0 0 21 21"
                                                fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#009473" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="21" rx="3"
                                                    fill="#662D50" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">

                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#FF4F58" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#0063B2" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="bold fs-18 pt-2 text-black">Traditional</div>
                                    <p class="fs-14 pt-0">
                                        A modern layout with a contrasting sidebar that catches the eye.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-disabled" role="tabpanel" aria-labelledby="pills-disabled-tab"
                            tabindex="0">
                            <div class="col-lg-3 mb-3 col-md-6 col-sm-12 col-12 border ml-2 box-shadow">
                                <div class="template-hover">
                                    <div class="position-relative">
                                        <div class="overlay-img">
                                            <router-link to="/build-resume-personal-summary">

                                                <button class="btn btn-light template-btn px-4 py-2 medium">Use This
                                                    Templete</button>
                                            </router-link>
                                        </div>
                                        <img src="@/assets/images/1.png" class="w-100" alt="" srcset="">
                                    </div>
                                    <div class="d-flex pt-3">
                                        <div id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer active-template" height="21" viewBox="0 0 21 21"
                                                fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#009473" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="21" rx="3"
                                                    fill="#662D50" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">

                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#FF4F58" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#0063B2" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="bold fs-18 pt-2 text-black">Traditional</div>
                                    <p class="fs-14 pt-0">
                                        A modern layout with a contrasting sidebar that catches the eye.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-disabled1" role="tabpanel"
                            aria-labelledby="pills-disabled-tab1" tabindex="0">
                            <div class="col-lg-3 mb-3 col-md-6 col-sm-12 col-12 border ml-2 box-shadow">
                                <div class="template-hover">
                                    <div class="position-relative">
                                        <div class="overlay-img">
                                            <router-link to="/build-resume-personal-summary">

                                                <button class="btn btn-light template-btn px-4 py-2 medium">Use This
                                                    Templete</button>
                                            </router-link>
                                        </div>
                                        <img src="@/assets/images/1.png" class="w-100" alt="" srcset="">
                                    </div>
                                    <div class="d-flex pt-3">
                                        <div id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer active-template" height="21" viewBox="0 0 21 21"
                                                fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#009473" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="21" rx="3"
                                                    fill="#662D50" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">

                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#FF4F58" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#0063B2" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="bold fs-18 pt-2 text-black">Traditional</div>
                                    <p class="fs-14 pt-0">
                                        A modern layout with a contrasting sidebar that catches the eye.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-disabled3" role="tabpanel"
                            aria-labelledby="pills-disabled-tab3" tabindex="0">
                            <div class="col-lg-3 mb-3 col-md-6 col-sm-12 col-12 border ml-2 box-shadow">
                                <div class="template-hover">
                                    <div class="position-relative">
                                        <div class="overlay-img">
                                            <router-link to="/build-resume-personal-summary">

                                                <button class="btn btn-light template-btn px-4 py-2 medium">Use This
                                                    Templete</button>
                                            </router-link>
                                        </div>
                                        <img src="@/assets/images/1.png" class="w-100" alt="" srcset="">
                                    </div>
                                    <div class="d-flex pt-3">
                                        <div id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer active-template" height="21" viewBox="0 0 21 21"
                                                fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#009473" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="21" rx="3"
                                                    fill="#662D50" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">

                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#FF4F58" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#0063B2" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="bold fs-18 pt-2 text-black">Traditional</div>
                                    <p class="fs-14 pt-0">
                                        A modern layout with a contrasting sidebar that catches the eye.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-disabled4" role="tabpanel"
                            aria-labelledby="pills-disabled-tab4" tabindex="0">
                            <div class="col-lg-3 mb-3 col-md-6 col-sm-12 col-12 border ml-2 box-shadow">
                                <div class="template-hover">
                                    <div class="position-relative">
                                        <div class="overlay-img">
                                            <router-link to="/build-resume-personal-summary">

                                                <button class="btn btn-light template-btn px-4 py-2 medium">Use This
                                                    Templete</button>
                                            </router-link>
                                        </div>
                                        <img src="@/assets/images/1.png" class="w-100" alt="" srcset="">
                                    </div>
                                    <div class="d-flex pt-3">
                                        <div id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer active-template" height="21" viewBox="0 0 21 21"
                                                fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#009473" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="21" rx="3"
                                                    fill="#662D50" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">

                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#FF4F58" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                        <div class="ms-1" id="color-template">
                                            <svg @click="changeColor" xmlns="http://www.w3.org/2000/svg" width="21"
                                                class=" cursor-pointer" height="21" viewBox="0 0 21 21" fill="none">
                                                <rect x="0.0209961" y="0.814941" width="20" height="20" rx="3"
                                                    fill="#0063B2" />
                                                <rect x="6.021" y="6.81494" width="8" height="8" rx="1" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="bold fs-18 pt-2 text-black">Traditional</div>
                                    <p class="fs-14 pt-0">
                                        A modern layout with a contrasting sidebar that catches the eye.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mainmargintop">
            <div class="custom-container m-auto">
                <h1 class="bold fs-30 text-center mb-0 px-sm-3 px-3 px-lg-0 px-md-3">
                    Create Your Impressive Resume in 3 Easy Steps
                </h1>

                <div class="mt-5 row px-sm-3 px-3 px-lg-0 px-md-3">
                    <div class="col-lg-4 mb-lg-0 mb-sm-4 mb-4 text-center position-relative">
                        <img src="@/assets/images/curve1.png" class="curve-1" alt="" srcset="" />
                        <img src="@/assets/images/document-text.png" alt="" srcset="" />
                        <h4 class="bold text-black pt-4 mb-0">Select a Template</h4>
                        <p class="pt-3 mb-0">
                            Choose from our extensive collection of industry-specific
                            templates that match your style and career aspirations.
                        </p>
                    </div>
                    <div class="col-lg-4 mb-lg-0 mb-sm-4 mb-4 text-center position-relative">
                        <img src="@/assets/images/curve2.png" class="curve-2" alt="" srcset="" />

                        <img src="@/assets/images/edit.png" alt="" srcset="" />
                        <h4 class="bold text-black pt-4 mb-0">Customize Your Content</h4>
                        <p class="pt-3 mb-0">
                            Personalize each section of your resume, including contact
                            information, professional summary, work experience, education, and
                            skills.
                        </p>
                    </div>
                    <div class="col-lg-4 mb-lg-0 mb-sm-4 mb-4 text-center position-relative">
                        <img src="@/assets/images/curve3.png" alt="" class="curve-3" srcset="">
                        <img src="@/assets/images/document-download.png" alt="" srcset="" />
                        <h4 class="bold text-black pt-4 mb-0">Download and Share</h4>
                        <p class="pt-3 mb-0">
                            With just one click, download your professionally designed resume
                            in various formats (PDF, Word, HTML) for easy sharing and
                            printing.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="mainmargintop">
            <div class="custom-container px-lg-5 px-sm-3 px-3 m-auto">
                <div class="position-relative template-bottom">
                    <div class="d-flex align-items-center">
                        <img src="@/assets/images/lamp-charge.png" alt="" srcset="">
                        <h3 class="fs-30 text-yellow bold mb-0 ps-2">Pro Tips</h3>
                    </div>
                    <p class="mb-0 pt-3">
                        Crafting a standout resume involves more than just design. Explore our expert tips and resources to
                        enhance
                        your resume-writing skills. Discover valuable insights on creating a compelling professional
                        summary,
                        showcasing achievements, and optimizing your resume for applicant tracking systems (ATS). Stay
                        updated with
                        the latest trends and best practices to maximize your chances of success.
                    </p>
                    <img src="@/assets/images/s.svg" class="bottom-s" alt="" srcset="">
                </div>
            </div>
        </section>
        <section class="mainmargintop">
        </section>
        <img src="@/assets/images/curve.png" class="w-100" alt="" srcset="">
        <section class="bg-curve w-100  ">
            <div class="row w-100 mx-0 ">
                <div class="col-lg-3  ps-0 d-lg-flex d-sm-none d-none align-items-end">
                    <img src="@/assets/images/left-resume.png" alt="" class="w-100" srcset="" />
                </div>
                <div class="col-lg-6 py-lg-0 py-sm-4 py-4 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <h3 class="fs-30 bold text-white mb-0">
                            Ready to start making your resume?
                        </h3>
                        <p class="mb-0 pt-3 text-white ready-start-p m-auto fs-18">
                            Don't let your resume hold you back from getting the job you want.
                            Our builder software helps you create a resume that highlights your
                            qualifications and lands you more interviews.
                        </p>
                        <div>
                            <router-link to="/build-resume-personal-summary">
                                <button
                                    class="hero-primary-btn d-flex align-items-center justify-content-center bg-blue mt-3">
                                    Build Resume
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 pe-0 d-flex align-items-end">
                    <img src="@/assets/images/right-resume.png" class="w-100" alt="" srcset="" />
                </div>
            </div>
        </section>

        <footer class="bg-black pt-5">
            <div class="custom-container px-lg-5 px-sm-3 px-3 py-lg-4 m-auto">
                <div class="row w-100">
                    <div class="col-lg-3 mb-lg-0 mb-sm-4 mb-4">
                        <img src="@/assets/images/white-logo.png" alt="" srcset="" />
                        <div class="text-white pt-3">
                            <span class="medium">Job Crafted:</span> Crafting Your Path to a
                            Successful Career
                        </div>
                        <div class="d-flex pt-4">
                            <img class="cursor-pointer" src="@/assets/images/fb.png" alt="" srcset="" />
                            <img class="cursor-pointer ms-3" src="@/assets/images/instagram.png" alt="" srcset="" />
                            <img class="cursor-pointer ms-3" src="@/assets/images/whatsapp.png" alt="" srcset="" />
                            <img class="cursor-pointer ms-3" src="@/assets/images/linkedin.png" alt="" srcset="" />
                        </div>
                    </div>
                    <div class="col-lg-3 mb-lg-0 mb-sm-4 mb-4 d-flex align-items-center justify-content-lg-center">
                        <div class="">
                            <div class="bold text-white">Resources</div>
                            <div class="pt-3">
                                <a href="#" class="d-flex light text-white">Blog</a>
                            </div>
                            <div class="pt-3">
                                <a href="#" class="d-flex light text-white">Career Advice</a>
                            </div>
                            <div class="pt-3">
                                <!-- <a href="#" class="d-flex light text-white">FAQ</a> -->
                                <router-link to="/FAQ" class="d-flex light text-white">FAQs</router-link>
                            </div>
                            <div class="pt-3">
                                <a href="#" class="d-flex light text-white">Terms of Service</a>
                            </div>
                            <div class="pt-3">
                                <a href="#" class="d-flex light text-white">Help & Support</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 mb-lg-0 mb-sm-4 mb-4 d-flex justify-content-lg-center">
                        <div class="">
                            <div class="bold text-white">Company</div>
                            <div class="pt-3 -flex">
                                <a href="#" class="d-flex light text-white">About Us</a>
                            </div>
                            <div class="pt-3">
                                <a href="#" class="d-flex light text-white">Our Team</a>
                            </div>
                            <div class="pt-3">
                                <a href="#" class="d-flex light text-white">Testimonials</a>
                            </div>
                            <div class="pt-3">
                                <a href="#" class="d-flex light text-white">Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 d-flex justify-content-lg-center">
                        <div class="">
                            <div class="bold text-white">Contact Us</div>
                            <div class="pt-3 d-flex align-items-center">
                                <img src="@/assets/images/sms.png" alt="" srcset="" />
                                <a href="#" class="d-flex light text-white ps-2">Exapmle@mail.com</a>
                            </div>
                            <div class="pt-3 d-flex align-items-center">
                                <img src="@/assets/images/call.png" alt="" srcset="" />
                                <a href="#" class="d-flex light text-white ps-2">+1 123-456-7890</a>
                            </div>
                            <div class="pt-3 d-flex align-items-center">
                                <img src="@/assets/images/location.png" alt="" srcset="" />
                                <a href="#" class="d-flex light text-white ps-2">123 Main St, City, State</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-container border-bottom m-auto pt-5"></div>
            <p class="text-white text-center py-4 mb-0">
                © 2023, Job Crafted. All rights reserved
            </p>
        </footer>

    </div>
</template>
    
<script>
import 'bootstrap'; // This imports Bootstrap JavaScript
// Import Swiper Vue.js components
// import { Swiper, SwiperSlide } from 'swiper/vue';
import $ from "jquery";
import FrontendHeader from "./FrontendHeader.vue";

// Import Swiper styles
import 'swiper/css';
export default {
    name: 'template',
    components: {
        FrontendHeader
    },
    setup() {

        return {

        };
    },

    methods: {
        changeColor(event) {
            $(event.target).removeClass('active-template');
            $(event.target).parent().addClass('active-template');

        },
        logout() {
            // Delete the accessToken key from storage
            localStorage.removeItem('accessToken'); // Replace with your storage mechanism

            // Redirect to the login page
            this.$router.push('/login');
        },
    },
    created() {
        // Check if the user is already logged in based on the presence of accessToken in storage
        const accessToken = localStorage.getItem('accessToken'); // Replace with your storage mechanism

        if (accessToken) {
            this.isLoggedIn = true;
        }
    },
    computed: {
        hasAccessToken() {
            // Check if "accessToken" exists in storage and return true or false accordingly
            const accessToken = localStorage.getItem('accessToken'); // You can replace 'localStorage' with 'sessionStorage' if needed
            return !!accessToken;
        },
    }
}
</script>
    
    <!-- Add your CSS styles here or link to an external CSS file if needed -->
<style scoped>
/* Your CSS styles go here */
@import '../assets/css/global.css';
/* Update the path as needed */
@import 'bootstrap/dist/css/bootstrap.min.css';
</style>
    