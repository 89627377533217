<template>
  <section>
    <AdminHeader />

    <div class="dashboard-main">
      <div class="d-flex mx-0">
        <div class="dashboard-main-left sticky-top bg-black h-100">
          <div>
            <img
              src="@/assets/images/white-logo.png"
              class="dashbaord-whitelogo"
              alt=""
            />
          </div>
          <div class="timeline-dashboard">
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 1 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Personal Information
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 2 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Professional Summary
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 3 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Work Experience
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 4 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">Education</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 5 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">Skills</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 6 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Projects or Achievements
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 7 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Additional Information
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 8 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">References</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  9
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Final Review and Download
              </div>
            </div>
          </div>
          <!-- Links and copyright -->
          <div class="mb-3 mt-5 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Privacy Policy </span>
          </div>
          <div class="mb-3 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Terms & Conditions </span>
          </div>
          <div class="mb-3 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Contact Us </span>
          </div>
          <div class="text-white fs-12 mt-5 d-lg-block d-none d-sm-none">
            © 2023, Job Crafted. All rights reserved
          </div>
        </div>
        <div class="dashboard-main-right ps-lg-5 ps-sm-1 ps-1">
          <div class="row dashboard-row">
            <div class="col-lg-9 dashboard-mid-col">
              <form id="educationForm" method="post">
                <div class="dashboard-mid">
                  <h4 class="text-white bold mb-0">Education</h4>
                  <p class="mb-0 text-white pt-2">
                    Provide details about your degrees, certifications, and
                    relevant coursework, highlighting your academic achievements
                    and qualifications.
                  </p>
                </div>
                <div class="mt-4">
                  <label class="bold text-black mb-2"
                    >What degrees or certifications have you obtained?</label
                  >
                  <select
                    name="certification"
                    v-model="certification"
                    class="custom-input input-height w-100"
                  >
                    <option value="" selected disabled>
                      Select your degrees or certifications
                    </option>
                    <option value="Affiliate">Affiliate</option>
                    <option value="Agile Professional Certified">
                      Agile Professional Certified
                    </option>
                    <option value="Associate">Associate</option>
                    <option value="AWS Certified Developer – Associate">
                      AWS Certified Developer – Associate
                    </option>
                    <option
                      value="AWS Certified Solutions Architect – Associate"
                    >
                      AWS Certified Solutions Architect – Associate
                    </option>
                    <option value="Blackbelt Certified">
                      Blackbelt Certified
                    </option>
                    <option value="Blackbelt Trained">Blackbelt Trained</option>
                    <option value="Business Continuity Management Professional">
                      Business Continuity Management Professional
                    </option>
                    <option value="Career Service Eligibility">
                      Career Service Eligibility
                    </option>
                    <option value="Certificate in Instructional Design">
                      Certificate in Instructional Design
                    </option>
                    <option value="Certificate in Manufacturing Technology">
                      Certificate in Manufacturing Technology
                    </option>
                    <option value="Certified Advanced Equity Analyst">
                      Certified Advanced Equity Analyst
                    </option>
                    <option value="Certified Advanced Technical Analyst">
                      Certified Advanced Technical Analyst
                    </option>
                    <option value="Certified data professional (CDP)">
                      Certified data professional (CDP)
                    </option>
                    <option value="Certified Ethical Hacker (CEH)">
                      Certified Ethical Hacker (CEH)
                    </option>
                    <option value="Certified Financial Analyst">
                      Certified Financial Analyst
                    </option>
                    <option value="Certified Financial Consultant">
                      Certified Financial Consultant
                    </option>
                    <option value="Certified Financial Markets Professional">
                      Certified Financial Markets Professional
                    </option>
                    <option value="Certified Human Resource Associates">
                      Certified Human Resource Associates
                    </option>
                    <option
                      value="Certified in Risk &amp; Information Systems Control (CRISC)"
                    >
                      Certified in Risk &amp; Information Systems Control
                      (CRISC)
                    </option>
                    <option
                      value="Certified Information Security Manager (CISM)"
                    >
                      Certified Information Security Manager (CISM)
                    </option>
                    <option
                      value="Certified Information Systems Auditor (CISA)"
                    >
                      Certified Information Systems Auditor (CISA)
                    </option>
                    <option
                      value="Certified Information Systems Security Professional (CISSP )"
                    >
                      Certified Information Systems Security Professional (CISSP
                      )
                    </option>
                    <option value="Certified Internal Auditor">
                      Certified Internal Auditor
                    </option>
                    <option value="Certified Professional Coder">
                      Certified Professional Coder
                    </option>
                    <option value="Certified Public Accountant">
                      Certified Public Accountant
                    </option>
                    <option value="Certified Scrum Master (CSM)">
                      Certified Scrum Master (CSM)
                    </option>
                    <option value="Certified Tax Technician">
                      Certified Tax Technician
                    </option>
                    <option
                      value="Certified Tester Professional Level (CTFL) - ISTQB"
                    >
                      Certified Tester Professional Level (CTFL) - ISTQB
                    </option>
                    <option value="Cisco certified internetwork expert (CCIE)">
                      Cisco certified internetwork expert (CCIE)
                    </option>
                    <option value="Cisco certified network associate (CCNA)">
                      Cisco certified network associate (CCNA)
                    </option>
                    <option value="Cisco certified network professional (CCNP)">
                      Cisco certified network professional (CCNP)
                    </option>
                    <option value="CompTIA A+">CompTIA A+</option>
                    <option value="CompTIA Security+">CompTIA Security+</option>
                    <option value="Fellow">Fellow</option>
                    <option value="FMEA ( Failure Mode and Effects Analysis )">
                      FMEA ( Failure Mode and Effects Analysis )
                    </option>
                    <option
                      value="Global information assurance certification (GIAC)"
                    >
                      Global information assurance certification (GIAC)
                    </option>
                    <option
                      value="Google Certified Professional Cloud Architect"
                    >
                      Google Certified Professional Cloud Architect
                    </option>
                    <option value="Greenbelt Certified">
                      Greenbelt Certified
                    </option>
                    <option value="Greenbelt Trained">Greenbelt Trained</option>
                    <option value="HIPAA Compliance">HIPAA Compliance</option>
                    <option value="ISO 9001 Certified">
                      ISO 9001 Certified
                    </option>
                    <option value="ITIL">ITIL</option>
                    <option value="JLPT N1">JLPT N1</option>
                    <option value="JLPT N2">JLPT N2</option>
                    <option value="JLPT N3">JLPT N3</option>
                    <option value="JLPT N4">JLPT N4</option>
                    <option value="Licensed Chemical Engineer">
                      Licensed Chemical Engineer
                    </option>
                    <option value="Licensed Civil Engineer">
                      Licensed Civil Engineer
                    </option>
                    <option value="Licensed Electrical Engineer">
                      Licensed Electrical Engineer
                    </option>
                    <option value="Licensed Mechanical Engineer">
                      Licensed Mechanical Engineer
                    </option>
                    <option value="Licensed Professional Teacher">
                      Licensed Professional Teacher
                    </option>
                    <option value="Licensed Psychometrician ">
                      Licensed Psychometrician
                    </option>
                    <option value="Masterblackbelt Certified">
                      Masterblackbelt Certified
                    </option>
                    <option value="Masterblackbelt Trained">
                      Masterblackbelt Trained
                    </option>
                    <option value="MBA">MBA</option>
                    <option value="Microsoft technology associate (MTA)">
                      Microsoft technology associate (MTA)
                    </option>
                    <option value="MS Azure Fundamentals">
                      MS Azure Fundamentals
                    </option>
                  </select>
                </div>
                <div class="mt-4">
                  <label class="bold text-black mb-2"
                    >Which institutions did you attend?</label
                  >
                  <select
                    name="institute"
                    v-model="institute"
                    class="custom-input input-height w-100"
                    id=""
                  >
                    <option value="" selected disabled>
                      Select the institution names
                    </option>
                    <option value="Harvard University">
                      Harvard University
                    </option>
                    <option value="Stanford University">
                      Stanford University
                    </option>
                    <option value="Massachusetts Institute of Technology (MIT)">
                      Massachusetts Institute of Technology (MIT)
                    </option>
                    <option value="University of Oxford">
                      University of Oxford
                    </option>
                    <option value="University of Cambridge">
                      University of Cambridge
                    </option>
                    <option
                      value="California Institute of Technology (Caltech)"
                    >
                      California Institute of Technology (Caltech)
                    </option>
                    <option value="Princeton University">
                      Princeton University
                    </option>
                    <option value="Yale University">Yale University</option>
                    <option value="University of Chicago">
                      University of Chicago
                    </option>
                    <option value="Columbia University">
                      Columbia University
                    </option>
                    <option value="Imperial College London">
                      Imperial College London
                    </option>
                    <option value="University of California, Berkeley">
                      University of California, Berkeley
                    </option>
                    <option value="University of Tokyo">
                      University of Tokyo
                    </option>
                    <option
                      value="ETH Zurich - Swiss Federal Institute of Technology"
                    >
                      ETH Zurich - Swiss Federal Institute of Technology
                    </option>
                    <option value="University of Toronto">
                      University of Toronto
                    </option>
                    <option value="University of Michigan">
                      University of Michigan
                    </option>
                    <option value="University of Edinburgh">
                      University of Edinburgh
                    </option>
                    <option value="University of Sydney">
                      University of Sydney
                    </option>
                    <option value="National University of Singapore (NUS)">
                      National University of Singapore (NUS)
                    </option>
                  </select>
                </div>
                <div class="mt-4 row marginSmZero align-items-end">
                  <div class="col-lg-12 paddingSmZero">
                    <label class="bold text-black mb-2"
                      >When did you graduate?</label
                    >
                    <div class="row align-items-end marginSmZero">
                      <div class="col-lg-4 paddingSmZero">
                        <select
                          name="graduate_date"
                          v-model="graduate_date"
                          class="custom-input input-height w-100"
                          id=""
                        >
                          <option value="" selected disabled>Date</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">13</option>
                          <option value="12">14</option>
                          <option value="12">15</option>
                          <option value="12">16</option>
                          <option value="12">17</option>
                          <option value="12">18</option>
                          <option value="12">19</option>
                          <option value="12">21</option>
                          <option value="12">22</option>
                          <option value="12">23</option>
                          <option value="12">24</option>
                          <option value="12">25</option>
                          <option value="12">26</option>
                          <option value="12">27</option>
                          <option value="12">28</option>
                          <option value="12">29</option>
                          <option value="12">30</option>
                          <option value="12">31</option>
                        </select>
                      </div>
                      <div class="col-lg-4 mt-lg-0 mt-sm-4 mt-4 paddingSmZero">
                        <select
                          name="graduate_month"
                          v-model="graduate_month"
                          class="custom-input input-height w-100"
                          id=""
                        >
                          <option value="" selected disabled>Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="1October">October</option>
                          <option value="1November">November</option>
                          <option value="1December">December</option>
                        </select>
                      </div>
                      <div class="col-lg-4 mt-lg-0 mt-sm-4 mt-4 paddingSmZero">
                        <select
                          name="graduate_year"
                          v-model="graduate_year"
                          class="custom-input input-height w-100"
                          id=""
                        >
                          <option value="" selected disabled>year</option>
                          <option
                            :value="year"
                            v-for="year in yearList"
                            :key="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <a href="./work_experience.html">
                    <button class="border-0 py-2 px-4 btn-skip px-4">
                      Skip
                    </button>
                  </a>

                  <button
                    class="border-0 py-2 px-4 text-white btn-next px-4"
                    type="button"
                    @click="saveEducationInfoData"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
            <div class="col-lg-3 ps-lg-4 ps-sm-3 ps-3 mt-lg-0 mt-sm-4 mt-4">
              <img src="" class="w-100" alt="" srcset="" />
              <div class="pt-3">
                <button
                  class="outline-btn-pdf w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <img src="@/assets/images/eye.png" alt="" srcset="" /> &nbsp;
                  <span
                    class="text-009473 medium"
                    @click="viewUserResume('default')"
                    >View Resume</span
                  >
                </button>
              </div>
              <div class="pt-3">
                <button
                  class="outline-btn-pdf-black w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                >
                  <img
                    src="@/assets/images/refresh-circle.png"
                    alt=""
                    srcset=""
                  />
                  &nbsp;
                  <span class="text-black medium">Apply Template</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <selectTemplateModal />
  </section>
</template>
        
    
<script>
import "bootstrap";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css"; // This imports Bootstrap JavaScript
import axios from "axios"; // Import Axios
import $ from "jquery";
import AdminHeader from "./AdminHeader.vue";
import selectTemplateModal from "./selectTemplateModal.vue";

var selectedSkills = [];
export default {
  components: {
    AdminHeader,
    selectTemplateModal,
  },

  mounted() {
    const accessToken = localStorage.getItem("accessToken");

    // Create a data object to send with the request
    const requestData = {
      accessToken: accessToken,
    };
    // Make a POST request to your API endpoint
    axios
      .post("https://admin1.jobcrafted.com/api/get-education-info", requestData)
      .then((response) => {
        // Handle a successful response here
        if (response.data) {
          if (response.data.result == "temp") {
            // Get the saved form data from localStorage
            const savedFormData = localStorage.getItem("educationFormData");

            // Parse the saved JSON data into a JavaScript object
            const formDataObject = JSON.parse(savedFormData);

            // Define a response.data object with the desired properties and values
            response.data = formDataObject;
          }
          // Set the selected values from the response data
          this.certification = response.data.certification || "";
          this.institute = response.data.institute || "";
          this.graduate_date = response.data.graduate_date || "";
          this.graduate_month = response.data.graduate_month || "";
          this.graduate_year = response.data.graduate_year || "";
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error sending data", error);
      });
  },
  computed: {
    yearList() {
      const currentYear = new Date().getFullYear();
      return Array.from(
        { length: currentYear - 1939 },
        (_, i) => 1940 + i
      ).sort((a, b) => b - a);
    },
  },
  name: "educationSUmmary",
  methods: {
    changeTemplate(template) {
      $("#template").val(template);
      var colorToApply = $("#color").val();
      const accessToken = localStorage.getItem("accessToken");
      $(".small-image-pdf").removeClass("activeSelect");
      $("." + template).addClass("activeSelect");
      // Define the key-value pairs
      var formData = new FormData();
      const data = {
        professionalForm: {
          professionaSummary: "aaa",
          skills: "",
          access_token: "null",
        },
        workExperienceForm: {
          company_name: "Cricket",
          start_month: "March",
          start_year: "1944",
          end_month: "3",
          end_year: "1949",
          skills: "Marketing Specialist",
          access_token: "null",
        },
        educationFormData: {
          certification: "AWS Certified Solutions Architect – Associate",
          institute: "University of Oxford",
          graduate_date: "3",
          graduate_month: "May",
          graduate_year: "1945",
          skills: "",
          access_token: "null",
        },
        skillFormData: { skills: "as,as", access_token: "null" },
        projectsForm: {
          projectDescription: "as",
          projectOutcomes: "asa",
          access_token: "null",
        },
        additionalFormData: {
          selectedLanguage: "Chinese",
          professionalAffiliations: "NoAffiliation",
          volunteerWork: "no work",
          access_token: "null",
        },
        referenceFormData: {},
      };

      // Loop through the key-value pairs and append them to the FormData object
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          // Convert the object to a JSON string and append it to FormData
          formData.append(key, JSON.stringify(data[key]));
        }
      }
      // Create a data object to send with the request
      const requestData = {
        accessToken: accessToken,
        template: template,
        colorToApply: colorToApply,
        formData: formData,
      };
      axios
        .post(
          "https://admin1.jobcrafted.com/api/get-reference-info-template",
          requestData
        )
        .then((response) => {
          // Handle a successful response here
          if (response.data) {
            $("#templateModal").html(response.data);
            $("#templateModalChoose").html(response.data);

            toast("Template Applied Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            toast("Template can be applied", {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
          // this.$router.push('/personal_summary');
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);
        });
    },
    viewUserResume(templateName) {
      const formData = new FormData();
      const accessToken = localStorage.getItem("accessToken");
      formData.append("accessToken", accessToken);
      formData.append("template", templateName);
      axios
        .post(
          "https://admin1.jobcrafted.com/api/get-reference-info-template",
          formData
        )
        .then((response) => {
          $("#templateModal").html(response.data);
          // return;
          // Handle a successful response here
          if (response.data.code == 200) {
            toast("Data Saved Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            this.validationErrors = response.data.result;
            $("#errorDiv").append(`<p class="mb-0 text-red pt-2"></p>`);
            $("#errorDiv").append(
              `<p class="mb-0 text-red pt-2"> - ${this.validationErrors}</p>`
            );
            toast(this.validationErrors[0], {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);

          // Display an error message to the user if needed
        });
    },
    saveEducationInfoData() {
      // Get the form data
      const formData = new FormData(document.getElementById("educationForm"));
      formData.append("skills", selectedSkills);
      const accessToken = localStorage.getItem("accessToken");
      formData.append("access_token", accessToken);
      // Make a POST request to your API endpoint
      axios
        .post("https://admin1.jobcrafted.com/api/save-education-info", formData)
        .then((response) => {
          // Handle a successful response here
          if (response.data.code == 200) {
            // Check if the response is 'temp' and save the form data to storage
            if (response.data.result === "temp") {
              const personalForm = document.getElementById("educationForm");
              const formData = new FormData(personalForm);
              formData.append("skills", selectedSkills);
              const accessToken = localStorage.getItem("accessToken");
              formData.append("access_token", accessToken);
              localStorage.setItem(
                "educationFormData",
                JSON.stringify(Object.fromEntries(formData.entries()))
              );
            }
            toast("Data Saved Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            this.validationErrors = response.data.result;
            toast(this.validationErrors[0], {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }

          this.$router.push("/build-resume-skills-summary");
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);

          // Display an error message to the user if needed
        });
    },
  },
  data() {
    return {
      summary: "",
      validationErrors: [], // Add this property to hold validation errors
      certification: null,
      institute: null,
      graduate_date: null,
      graduate_month: null,
      graduate_year: null,
    };
  },
};
</script>
    
<style scoped>
/* Your component-specific styles go here */
.validation-errors {
  color: red;
}
</style>
    
      
        
        <!-- Add your CSS styles here or link to an external CSS file if needed -->
<style scoped>
/* Import CSS */
@import "@/assets/css/global.css";
@import "@/assets/css/dashboard.css";

@import "bootstrap/dist/css/bootstrap.min.css";
</style>
        