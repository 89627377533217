<template>
  <div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body p-0">
            <img
              class="close-popup-del cursor-pointer"
              src="@/assets/images/light-close.png"
              data-bs-dismiss="modal"
              aria-label="Close"
              alt=""
              srcset=""
            />

            <div class="col-lg-12" id="templateModal"></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal1"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body p-0">
            <img
              class="close-popup-del cursor-pointer"
              src="@/assets/images/light-close.png"
              data-bs-dismiss="modal"
              aria-label="Close"
              alt=""
              srcset=""
            />
            <div class="row p-lg-4 p-sm-3 p-3 marginSmZero">
              <div class="col-lg-7 paddingSmZero">
                <div class="col-lg-12" id="templateModalChoose"></div>
              </div>
              <div class="col-lg-5 paddingSmZero mt-lg-0 mt-sm-4 mt-4">
                <div class="p-3 template-right-preview">
                  <h6 class="bold text-black mb-4">Choose a New Template</h6>
                  <div class="row small-image-pdf-row-1">
                    <div
                      class="col-lg-6 col-sm-6 col-6 mb-3"
                      @click="changeTemplate('default')"
                    >
                      <img
                        src="@/assets/images/1.png"
                        class="default w-100 small-image-pdf"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div
                      class="col-lg-6 col-sm-6 col-6 mb-3"
                      @click="changeTemplate('template1')"
                    >
                      <img
                        src="@/assets/images/2.png"
                        class="template1 w-100 small-image-pdf activeSelect"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div
                      class="col-lg-6 col-sm-6 col-6 mb-3"
                      @click="changeTemplate('template2')"
                    >
                      <img
                        src="@/assets/images/3.png"
                        class="template2 w-100 small-image-pdf"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div
                      class="col-lg-6 col-sm-6 col-6 mb-3"
                      @click="changeTemplate('template3')"
                    >
                      <img
                        src="@/assets/images/4.png"
                        class="template3 w-100 small-image-pdf"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div
                      class="col-lg-6 col-sm-6 col-6 mb-3"
                      @click="changeTemplate('template4')"
                    >
                      <img
                        src="@/assets/images/5.png"
                        class="template4 w-100 small-image-pdf"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div
                      class="col-lg-6 col-sm-6 col-6 mb-3"
                      @click="changeTemplate('template5')"
                    >
                      <img
                        src="@/assets/images/6.png"
                        class="template4 w-100 small-image-pdf"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                  <div class="mt-4">
                    <button
                      type="button"
                      class="btn-cancel btn cancel-popup medium px-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      class="btn-change_template btn cancel-popup medium px-4 ms-lg-3 text-white"
                      data-bs-dismiss="modal"
                    >
                      Apply Template
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import axios from "axios";
import $ from "jquery";

export default {
  methods: {
    changeTemplate(template) {
      $("#template").val(template);
      var colorToApply = $("#color").val();
      const accessToken = localStorage.getItem("accessToken");
      $(".small-image-pdf").removeClass("activeSelect");
      $("." + template).addClass("activeSelect");
      var formData = new FormData();
      const data = {
        professionalForm: {
          professionaSummary: "aaa",
          skills: "",
          access_token: "null",
        },
        workExperienceForm: {
          company_name: "Cricket",
          start_month: "March",
          start_year: "1944",
          end_month: "3",
          end_year: "1949",
          skills: "Marketing Specialist",
          access_token: "null",
        },
        educationFormData: {
          certification: "AWS Certified Solutions Architect – Associate",
          institute: "University of Oxford",
          graduate_date: "3",
          graduate_month: "May",
          graduate_year: "1945",
          skills: "",
          access_token: "null",
        },
        skillFormData: { skills: "as,as", access_token: "null" },
        projectsForm: {
          projectDescription: "as",
          projectOutcomes: "asa",
          access_token: "null",
        },
        additionalFormData: {
          selectedLanguage: "Chinese",
          professionalAffiliations: "NoAffiliation",
          volunteerWork: "no work",
          access_token: "null",
        },
        referenceFormData: {},
      };

      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          formData.append(key, JSON.stringify(data[key]));
        }
      }
      const requestData = {
        accessToken: accessToken,
        template: template,
        colorToApply: colorToApply,
        formData: data,
      };
      axios
        .post(
          "https://admin1.jobcrafted.com/api/get-reference-info-template",
          requestData
        )
        .then((response) => {
          if (response.data) {
            $("#templateModal").html(response.data);
            $("#templateModalChoose").html(response.data);

            toast("Template Applied Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            toast("Template can be applied", {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
        })
        .catch((error) => {
          console.error("Error sending data", error);
        });
    },
  },
};
</script>

<style scoped>
@import "../assets/css/global.css";
@import "../assets/css/dashboard.css";

@import "bootstrap/dist/css/bootstrap.min.css";
</style>
    