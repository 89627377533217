<template>
  <div>
    <!-- <div class="grad-header"></div>
        <header class="header px-lg-0 px-3 px-sm-3">
            <nav class="navbar">
                <router-link to="/" class="nav-logo js-anchor-link">
                    <img src="@/assets/images/logo.png" alt="" srcset="" />
                </router-link>
                <ul class="nav-menu mb-0 phs-0">

                    <li class="nav-item">
                        <router-link to="/" class="nav-link px-3 medium js-anchor-link">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <a href="./template.html" class="nav-link px-3 medium js-anchor-link">Templates</a>
                    </li>
                    <li class="nav-item">
                        <router-link to="/how-to-write-resume" class="nav-link px-3 medium js-anchor-link">How to
                            Write</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/FAQ" class="nav-link px-3 medium js-anchor-link">FAQs</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/login">
                            <button
                                class="hero-primary-btn d-flex align-items-center px-5 h-auto py-sm-2 ms-auto w-100 justify-content-center bg-blue">
                                Login
                            </button>
                        </router-link>
                    </li>
                </ul>
                <div class="hamburger">
                    <span class="bar"></span>
                    <span class="bar"></span>
                    <span class="bar"></span>
                </div>
            </nav>
        </header> -->
    <!-- navbar -->

    <FrontendHeader />

    <section class="mainmargintop">
      <div class="custom-container px-lg-5 px-sm-3 px-3 m-auto">
        <h1 class="bold fs-30 text-center mb-5">Frequently Asked Questions</h1>
        <input type="hidden" name="" value="false" id="faqsIdInputCheck" />
        <div v-for="(faq, index) in faqs" :key="index" class="faqs p-4 mb-4">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="bold mb-0">{{ faq.question }}</h4>
            <div
              @click="toggleFaq(index)"
              class="faqs-circle d-flex align-items-center justify-content-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="18"
                viewBox="0 0 11 19"
                fill="none"
                class="rotateSvg"
              >
                <path
                  d="M1.55945 17.9351L9.77872 9.67847L1.55945 1.42188"
                  stroke="#101010"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div class="faqs-desc" v-if="faq.toggle">
            {{ faq.answer }}
          </div>
        </div>
      </div>
    </section>
    <img
      src="@/assets/images/curve.png"
      class="w-100 mainmargintop"
      alt=""
      srcset=""
    />
    <section class="bg-curve w-100">
      <div class="row w-100 mx-0">
        <div class="col-lg-3 ps-0 d-lg-flex d-sm-none d-none align-items-end">
          <img
            src="@/assets/images/left-resume.png"
            alt=""
            class="w-100"
            srcset=""
          />
        </div>
        <div
          class="col-lg-6 py-lg-0 py-sm-4 py-4 text-center d-flex align-items-center justify-content-center"
        >
          <div>
            <h3 class="fs-30 bold text-white mb-0">
              Ready to start making your resume?
            </h3>
            <p class="mb-0 pt-3 text-white ready-start-p m-auto fs-18">
              Don't let your resume hold you back from getting the job you want.
              Our builder software helps you create a resume that highlights
              your qualifications and lands you more interviews.
            </p>
            <div>
              <router-link to="/build-resume-personal-summary">
                <button
                  class="hero-primary-btn d-flex align-items-center justify-content-center bg-blue mt-3"
                >
                  Build Resume
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-3 pe-0 d-flex align-items-end">
          <img
            src="@/assets/images/right-resume.png"
            class="w-100"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </section>

    <section class="mainmargintop">
      <div class="custom-container px-lg-5 px-sm-3 px-3 m-auto">
        <h1 class="bold fs-30 text-center mb-5">Frequently Asked Questions</h1>
        <div v-for="(faq, index) in faqs" :key="index" class="faqs p-4 mb-4">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="bold mb-0">{{ faq.question }}</h4>
            <div
              @click="toggleFaq(index)"
              class="faqs-circle d-flex align-items-center justify-content-center"
            >
              SVG for arrow, changed based on open status
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="18"
                viewBox="0 0 11 19"
                fill="none"
                :class="{ rotateSvg: openFaqIndex === index }"
              >
                <path
                  d="M1.55945 17.9351L9.77872 9.67847L1.55945 1.42188"
                  stroke="#101010"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div v-show="openFaqIndex === index" class="faqs-desc">
            {{ faq.answer }}
          </div>
        </div>
      </div>
    </section>

    <img
      src="@/assets/images/curve.png"
      class="w-100 mainmargintop"
      alt=""
      srcset=""
    />
    <section class="bg-curve w-100">
      <div class="row w-100 mx-0">
        <div class="col-lg-3 ps-0 d-lg-flex d-sm-none d-none align-items-end">
          <img
            src="@/assets/images/left-resume.png"
            alt=""
            class="w-100"
            srcset=""
          />
        </div>
        <div
          class="col-lg-6 py-lg-0 py-sm-4 py-4 text-center d-flex align-items-center justify-content-center"
        >
          <div>
            <h3 class="fs-30 bold text-white mb-0">
              Ready to start making your resume?
            </h3>
            <p class="mb-0 pt-3 text-white ready-start-p m-auto fs-18">
              Don't let your resume hold you back from getting the job you want.
              Our builder software helps you create a resume that highlights
              your qualifications and lands you more interviews.
            </p>
            <div>
              <button class="bg-02614C hero-primary-btn mt-3">
                Build Resume
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-3 pe-0 d-flex align-items-end">
          <img
            src="@/assets/images/right-resume.png"
            class="w-100"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </section>

    <footer class="bg-black pt-5">
      <div class="custom-container px-lg-5 px-sm-3 px-3 py-lg-4 m-auto">
        <div class="row w-100">
          <div class="col-lg-3 mb-lg-0 mb-sm-4 mb-4">
            <img src="@/assets/images/white-logo.png" alt="" srcset="" />
            <div class="text-white pt-3">
              <span class="medium">Job Crafted:</span> Crafting Your Path to a
              Successful Career
            </div>
            <div class="d-flex pt-4">
              <img
                class="cursor-pointer"
                src="@/assets/images/fb.png"
                alt=""
                srcset=""
              />
              <img
                class="cursor-pointer ms-3"
                src="@/assets/images/instagram.png"
                alt=""
                srcset=""
              />
              <img
                class="cursor-pointer ms-3"
                src="@/assets/images/whatsapp.png"
                alt=""
                srcset=""
              />
              <img
                class="cursor-pointer ms-3"
                src="@/assets/images/linkedin.png"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div
            class="col-lg-3 mb-lg-0 mb-sm-4 mb-4 d-flex align-items-center justify-content-lg-center"
          >
            <div class="">
              <div class="bold text-white">Resources</div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Blog</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Career Advice</a>
              </div>
              <div class="pt-3">
                <!-- <a href="#" class="d-flex light text-white">FAQ</a> -->
                <router-link to="/FAQ" class="d-flex light text-white"
                  >FAQs</router-link
                >
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Terms of Service</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Help & Support</a>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 mb-lg-0 mb-sm-4 mb-4 d-flex justify-content-lg-center"
          >
            <div class="">
              <div class="bold text-white">Company</div>
              <div class="pt-3 -flex">
                <a href="#" class="d-flex light text-white">About Us</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Our Team</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Testimonials</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Privacy Policy</a>
              </div>
            </div>
          </div>
          <div class="col-lg-3 d-flex justify-content-lg-center">
            <div class="">
              <div class="bold text-white">Contact Us</div>
              <div class="pt-3 d-flex align-items-center">
                <img src="@/assets/images/sms.png" alt="" srcset="" />
                <a href="#" class="d-flex light text-white ps-2"
                  >Exapmle@mail.com</a
                >
              </div>
              <div class="pt-3 d-flex align-items-center">
                <img src="@/assets/images/call.png" alt="" srcset="" />
                <a href="#" class="d-flex light text-white ps-2"
                  >+1 123-456-7890</a
                >
              </div>
              <div class="pt-3 d-flex align-items-center">
                <img src="@/assets/images/location.png" alt="" srcset="" />
                <a href="#" class="d-flex light text-white ps-2"
                  >123 Main St, City, State</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-container border-bottom m-auto pt-5"></div>
      <p class="text-white text-center py-4 mb-0">
        © 2023, Job Crafted. All rights reserved
      </p>
    </footer>
  </div>
</template>
    
<script>
import "bootstrap"; // This imports Bootstrap JavaScript
import FrontendHeader from "./FrontendHeader.vue";

export default {
  name: "FAQs",
  components: {
    FrontendHeader,
  },
  data() {
    return {
      openFaqIndex: null,
      faqs: [
        {
          question: "How Can I Use Job Crafted For Free?",
          answer:
            "Job Crafted has a few different tools you can use 100% for free without entering any credit card details.",
        },
        {
          question: "How can i customise my resume?",
          answer:
            "Job Crafted has a few different tools you can use 100% for free without entering any credit card details.",
        },
        {
          question: "can i use my resume to word or pdf?",
          answer:
            "Job Crafted has a few different tools you can use 100% for free without entering any credit card details.",
        },
        {
          question: "How do i cancel, downgrade or delete my account?",
          answer:
            "Job Crafted has a few different tools you can use 100% for free without entering any credit card details.",
        },
        // Add more FAQs here
      ],
    };
  },
  methods: {
    toggleFaq(index) {
      this.faqs[index].toggle = !this.faqs[index].toggle;
    },
    logout() {
      // Delete the accessToken key from storage
      localStorage.removeItem("accessToken"); // Replace with your storage mechanism

      // Redirect to the login page
      this.$router.push("/login");
    },
  },
  created() {
    // Check if the user is already logged in based on the presence of accessToken in storage
    const accessToken = localStorage.getItem("accessToken"); // Replace with your storage mechanism

    if (accessToken) {
      this.isLoggedIn = true;
    }
  },
  computed: {
    hasAccessToken() {
      // Check if "accessToken" exists in storage and return true or false accordingly
      const accessToken = localStorage.getItem("accessToken"); // You can replace 'localStorage' with 'sessionStorage' if needed
      return !!accessToken;
    },
  },
};
</script>
    
    <!-- Add your CSS styles here or link to an external CSS file if needed -->
<style scoped>
/* Your CSS styles go here */
@import "../assets/css/global.css";
/* Update the path as needed */
@import "bootstrap/dist/css/bootstrap.min.css";

/* Add your CSS styles here */
</style>
    