 
<template>
  <div class="container mt-5">
    <div class="container mainmargintop">
      <h1 class="bold mb43 payment-heading">Enter Payment Information</h1>
      <div class="row">
        <div class="col-lg-8">
          <div class="pp-wrapper">
            <div class="container">
              <form @submit.prevent="handlePayment">
                <div id="card-element">
                  <div class="row flex-column flex-lg-row">
                    <div class="col">
                      <label class="py-label" for="input1">Card Number</label>
                      <input
                        type="text"
                        class="form-control mt-2"
                        id="input1"
                        placeholder="Enter full name"
                      />
                    </div>
                    <div class="col mt-3 mt-lg-0">
                      <label class="py-label" for="input2">Security Code</label>
                      <input
                        type="text"
                        class="form-control mt-2"
                        id="input2"
                        placeholder="Enter full name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label class="py-label pt50" for="input1"
                        >Name on Card</label
                      >
                      <input
                        type="text"
                        class="form-control mt-2"
                        id="input1"
                        placeholder="Enter Name mentioned on Card"
                      />
                    </div>
                  </div>
                  <div class="row pt50 d-flex align-items-center">
                    <div class="col">
                      <label class="py-label" for="input1"
                        >Expiration Date</label
                      >
                      <div class="d-flex mt-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Month</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                        <select
                          class="form-select ms-3"
                          aria-label="Default select example"
                        >
                          <option selected>Year</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="payment-text-div mt-4">
                <p class="payment-text">
                  By clicking "Get your resume" below you agree to our Terms of
                  Use and Privacy Policy. You also understand that you will be
                  billed $$$, which will automatically renew every year. You can
                  cancel at any time.
                </p>
              </div>
              <div
                class="btnTemplatecarousel mt-5 text-start"
                data-v-df02a584=""
              >
                <button class="text-white" data-v-df02a584="">
                  Get Your Resume
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3 mt-lg-0">
          <div class="review-system">
            <p class="py-label">Review Your Resume</p>
            <ul class="">
              <li class="py-list">
                <span class="bold"> Item:</span> Annual Access
              </li>
              <li class="py-list">Unlimited Download, print and email.</li>
              <li class="py-list">Multiple Resume Templates</li>
              <li class="py-list">Resume Check</li>
            </ul>
          </div>
          <div class="py-total-payment">
            <div class="d-flex justify-content-between">
              <p class="text-white mb-0">Total Payment:</p>
              <p class="text-white mb-0">$71.40</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { loadStripe } from "@stripe/stripe-js";
import "bootstrap";
import { toast } from "vue3-toastify";
import $ from "jquery";

export default {
  setup() {
    const router = useRouter();
    const elements = ref(null);
    const stripe = ref(null);
    let clientSecret = null; // Declare clientSecret at a higher scope
    const downloadUserResume = (templateName) => {
      const formData = new FormData();
      const accessToken = localStorage.getItem("accessToken");
      formData.append("access_token", accessToken);
      formData.append("templateName", templateName);
      axios
        .post(
          "https://admin1.jobcrafted.com/api/download-user-resume",
          formData
        )
        .then((response) => {
          // Handle a successful response here
          if (response.data.code == 200) {
            toast("Data Saved Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            // Handle validation errors
            this.validationErrors = response.data.result;
            $("#errorDiv").append(`<p class="mb-0 text-red pt-2"></p>`);
            $("#errorDiv").append(
              `<p class="mb-0 text-red pt-2"> - ${this.validationErrors}</p>`
            );
            toast(this.validationErrors[0], {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
          //   this.$router.push("/build-resume-payment-page");
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);

          // Display an error message to the user if needed
        });
    };
    onMounted(async () => {
      // Fetch the client secret from the server based on route params
      try {
        const response = await axios.post(
          "https://admin1.jobcrafted.com/api/payment/initiate"
        );

        clientSecret = response.data.client_secret; // Assign clientSecret

        // Initialize Stripe
        const stripeKey =
          "pk_test_51O6xtFLkONkGt5tYJb3lKQ5ZMqEfVXUcvCyJwnMMl37fI5bcSyhH06kxaA5yMyjnJgIuracPsIqB9rHbKtVeGYsx00q2JeQGpw"; // Replace with your actual publishable key
        stripe.value = await loadStripe(stripeKey);

        const elementsOptions = {
          fonts: [{ cssSrc: "https://fonts.googleapis.com/css?family=Roboto" }],
        };
        elements.value = stripe.value.elements(elementsOptions);
        const cardElement = elements.value.create("card");
        cardElement.mount("#card-element");
      } catch (error) {
        console.error(error);
      }
    });

    const handlePayment = async () => {
      // Check if stripe is null
      if (!stripe.value || !clientSecret) {
        console.error("Stripe or clientSecret is not initialized yet.");
        return;
      }

      const result = await stripe.value.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.value.getElement("card"),
        },
      });

      if (result.error) {
        // Handle payment error
        console.error(result.error);
      } else {
        // Payment succeeded, you can redirect or show a success message
        router.push("/payment-success");
        downloadUserResume("default");
      }
    };

    return {
      handlePayment,
    };
  },

  methods: {
    // download pdf resume
    downloadUserResume(templateName) {
      const formData = new FormData();
      const accessToken = localStorage.getItem("accessToken");
      formData.append("access_token", accessToken);
      formData.append("templateName", templateName);
      axios
        .post(
          "https://admin1.jobcrafted.com/api/download-user-resume",
          formData
        )
        .then((response) => {
          // Handle a successful response here
          if (response.data.code == 200) {
            toast("Data Saved Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            this.validationErrors = response.data.result;
            $("#errorDiv").append(`<p class="mb-0 text-red pt-2"></p>`);
            $("#errorDiv").append(
              `<p class="mb-0 text-red pt-2"> - ${this.validationErrors}</p>`
            );
            toast(this.validationErrors[0], {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
          //   this.$router.push("/build-resume-payment-page");
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);

          // Display an error message to the user if needed
        });
    },
  },
};
</script>
<style scoped>
.payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  /* Set your desired background color */
}

.payment-card {
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  /* Set card background color */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for a card-like appearance */
  border-radius: 8px;
  text-align: center;
}

.card-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.card-element {
  /* Add any additional styles to customize the appearance of the card element */
}
</style>