<template>
  <div>
    <div class="grad-header"></div>
    <!-- <header class="header px-lg-0 px-3 px-sm-3">
            <nav class="navbar">
                <router-link to="/" class="nav-logo js-anchor-link">
                    <img src="@/assets/images/logo.png" alt="" srcset="" />
                </router-link>
                <ul class="nav-menu mb-0 phs-0">

                    <li class="nav-item">
                        <router-link to="/" class="nav-link px-3 medium js-anchor-link">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/resume-templates"
                            class="nav-link px-3 medium js-anchor-link">Templates</router-link>

                    </li>
                    <li class="nav-item">
                        <router-link to="/how-to-write-resume" class="nav-link px-3 medium js-anchor-link">How to
                            Write</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/FAQ" class="nav-link px-3 medium js-anchor-link">FAQs</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/login">
                            <button
                                class="hero-primary-btn d-flex align-items-center px-5 h-auto py-sm-2 ms-auto w-100 justify-content-center bg-blue">
                                Login
                            </button>
                        </router-link>
                    </li>
                </ul>
                <div class="hamburger">
                    <span class="bar"></span>
                    <span class="bar"></span>
                    <span class="bar"></span>
                </div>
            </nav>
        </header> -->

    <FrontendHeader />

    <section class="mainmargintop">
      <div class="custom-container px-lg-0 px-sm-3 px-3 m-auto">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <h1 class="hero-title extrabold ttu py-3 mb-0">
              Create Your
              <span class="text-red extrabold"> Professional </span> <br />
              <span class="text-red extrabold"> Resume </span> with Ease
            </h1>
            <p class="fs-18 mb-0">
              Design an impressive resume that stands out from the competition.
              Our free resume templates make it simple and hassle-free to craft
              a polished document that highlights your skills and experience.
              Whether you're a recent graduate or a seasoned professional, our
              user-friendly platform offers everything you need to showcase your
              qualifications and land your dream job.
            </p>
            <div>
              <router-link to="/build-resume-personal-summary">
                <button
                  class="hero-primary-btn d-flex align-items-center justify-content-center bg-blue mt-3"
                >
                  Get Started
                </button>
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 mt-lg-0 mt-sm-4 mt-4 text-end">
            <img
              src="@/assets/images/how-to-write.png"
              width="496px"
              class="sm_100"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </section>
    <section class="mainmargintop">
      <div class="custom-container px-lg-0 px-sm-3 px-3 m-auto">
        <h1 class="fs-30 mb-lg-5 mb-sm-3 mb-3 text-black bold text-center">
          Why Choose <span class="text-red extrabold"> Job Crafted </span> for
          Your Resume Needs?
        </h1>
        <div
          class="row align-items-center flex-sm-column-reverse flex-column-reverse flex-lg-row"
        >
          <div class="col-lg-6 mt-lg-0 mt-sm-4 mt-4 text-start">
            <img
              src="@/assets/images/how-to-write-1.png"
              width="496px"
              class="sm_100"
              alt=""
              srcset=""
            />
          </div>
          <div class="col-lg-6">
            <h4 class="extrabold text-black ttu py-3 mb-0">
              Simplify the Resume Writing Process
            </h4>
            <p class="fs-18 mb-0">
              Writing a resume can be overwhelming, but our platform simplifies
              the entire process. With our user-friendly interface and
              pre-designed templates, you can create a professional resume in
              minutes, eliminating the frustration of formatting and design.
              Focus on what matters most – crafting compelling content that
              highlights your expertise.
            </p>
            <div>
              <button
                class="primary-outline medium bg-blue mt-4"
                @click="$router.push('signup')"
              >
                Start Building Your Resume
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mainmargintop">
      <div class="custom-container px-lg-0 px-sm-3 px-3 m-auto">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <h4 class="extrabold text-black ttu py-3 mb-0">
              Customizable Templates for Every Industry
            </h4>
            <p class="fs-18 mb-0">
              We understand that every industry has unique requirements. That's
              why we offer a diverse range of customizable templates tailored to
              various fields. Whether you're in finance, technology, marketing,
              or any other sector, our templates are designed to showcase your
              skills and achievements effectively. Choose a template that
              resonates with your professional style and make a lasting
              impression on hiring managers.
            </p>
            <div>
              <button
                class="primary-outline medium bg-blue mt-4"
                @click="$router.push('signup')"
              >
                Start Building Your Resume
              </button>
            </div>
          </div>
          <div class="col-lg-6 mt-lg-0 mt-sm-4 mt-4 text-end">
            <img
              src="@/assets/images/how-to-write-2.png"
              width="496px"
              class="sm_100"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </section>
    <section class="mainmargintop">
      <div class="custom-container px-lg-0 px-sm-3 px-3 m-auto">
        <div
          class="row align-items-center flex-sm-column-reverse flex-column-reverse flex-lg-row"
        >
          <div class="col-lg-6 mt-lg-0 mt-sm-4 mt-4 text-start">
            <img
              src="@/assets/images/how-to-write-3.png"
              width="496px"
              class="sm_100"
              alt=""
              srcset=""
            />
          </div>
          <div class="col-lg-6">
            <h4 class="extrabold text-black ttu py-3 mb-0">
              Expert Guidance and Tips
            </h4>
            <p class="fs-18 mb-0">
              Crafting a standout resume requires more than just a visually
              appealing design. Our platform provides expert guidance and tips
              throughout the resume-building process. From writing an
              attention-grabbing summary to effectively presenting your
              experience, our resources help you optimize each section for
              maximum impact. Stand out from the crowd with our insider advice
              and make your resume shine.
            </p>
            <div>
              <button
                class="primary-outline medium bg-blue mt-4"
                @click="$router.push('signup')"
              >
                Start Building Your Resume
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mainmargintop">
      <div class="custom-container m-auto">
        <h1 class="bold fs-30 text-center mb-0 px-sm-3 px-3 px-lg-0 px-md-3">
          How It Works
        </h1>

        <div class="mt-5 row px-sm-3 px-3 px-lg-0 px-md-3">
          <div
            class="col-lg-4 mb-lg-0 mb-sm-4 mb-4 text-center position-relative"
          >
            <img
              src="@/assets/images/curve1.png"
              class="curve-1"
              alt=""
              srcset=""
            />
            <img src="@/assets/images/document-text.png" alt="" srcset="" />
            <h4 class="bold text-black pt-4 mb-0">Select a Template</h4>
            <p class="pt-3 mb-0">
              Choose from our extensive collection of industry-specific
              templates that match your style and career aspirations.
            </p>
          </div>
          <div
            class="col-lg-4 mb-lg-0 mb-sm-4 mb-4 text-center position-relative"
          >
            <img
              src="@/assets/images/curve2.png"
              class="curve-2"
              alt=""
              srcset=""
            />

            <img src="@/assets/images/edit.png" alt="" srcset="" />
            <h4 class="bold text-black pt-4 mb-0">Customize Your Content</h4>
            <p class="pt-3 mb-0">
              Personalize each section of your resume, including contact
              information, professional summary, work experience, education, and
              skills.
            </p>
          </div>
          <div
            class="col-lg-4 mb-lg-0 mb-sm-4 mb-4 text-center position-relative"
          >
            <img
              src="@/assets/images/curve3.png"
              alt=""
              class="curve-3"
              srcset=""
            />
            <img src="@/assets/images/document-download.png" alt="" srcset="" />
            <h4 class="bold text-black pt-4 mb-0">Download and Share</h4>
            <p class="pt-3 mb-0">
              With just one click, download your professionally designed resume
              in various formats (PDF, Word, HTML) for easy sharing and
              printing.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="mainmargintop">
      <div class="custom-container px-lg-5 px-sm-3 px-3 m-auto">
        <div class="position-relative template-bottom-2">
          <div class="d-flex align-items-center">
            <img src="@/assets/images/info-circle.svg" alt="" srcset="" />
            <h3 class="fs-30 text-blue bold mb-0 ps-2">Remember</h3>
          </div>
          <p class="mb-0 pt-3">
            your resume is your key to unlocking new opportunities. Invest in a
            well-crafted document that reflects your skills, experiences, and
            unique personality. Get started today and take a confident step
            towards a successful career.
          </p>
          <button class="hero-primary-btn bg-blue py-3 px-5 w-auto h-auto mt-4">
            Get Started Now
          </button>
          <img
            src="@/assets/images/blue-curve.png"
            class="bottom-s"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </section>
    <section class="mainmargintop"></section>
    <img src="@/assets/images/curve.png" class="w-100" alt="" srcset="" />
    <section class="bg-curve w-100">
      <div class="row w-100 mx-0">
        <div class="col-lg-3 ps-0 d-lg-flex d-sm-none d-none align-items-end">
          <img
            src="@/assets/images/left-resume.png"
            alt=""
            class="w-100"
            srcset=""
          />
        </div>
        <div
          class="col-lg-6 py-lg-0 py-sm-4 py-4 text-center d-flex align-items-center justify-content-center"
        >
          <div>
            <h3 class="fs-30 bold text-white mb-0">
              Ready to start making your resume?
            </h3>
            <p class="mb-0 pt-3 text-white ready-start-p m-auto fs-18">
              Don't let your resume hold you back from getting the job you want.
              Our builder software helps you create a resume that highlights
              your qualifications and lands you more interviews.
            </p>
            <div>
              <router-link to="/build-resume-personal-summary">
                <button
                  class="hero-primary-btn d-flex align-items-center justify-content-center bg-blue mt-3"
                >
                  Build Resume
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-3 pe-0 d-flex align-items-end">
          <img
            src="@/assets/images/right-resume.png"
            class="w-100"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </section>
    <footer class="bg-black pt-5">
      <div class="custom-container px-lg-5 px-sm-3 px-3 py-lg-4 m-auto">
        <div class="row w-100">
          <div class="col-lg-3 mb-lg-0 mb-sm-4 mb-4">
            <img src="@/assets/images/white-logo.png" alt="" srcset="" />
            <div class="text-white pt-3">
              <span class="medium">Job Crafted:</span> Crafting Your Path to a
              Successful Career
            </div>
            <div class="d-flex pt-4">
              <img
                class="cursor-pointer"
                src="@/assets/images/fb.png"
                alt=""
                srcset=""
              />
              <img
                class="cursor-pointer ms-3"
                src="@/assets/images/instagram.png"
                alt=""
                srcset=""
              />
              <img
                class="cursor-pointer ms-3"
                src="@/assets/images/whatsapp.png"
                alt=""
                srcset=""
              />
              <img
                class="cursor-pointer ms-3"
                src="@/assets/images/linkedin.png"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div
            class="col-lg-3 mb-lg-0 mb-sm-4 mb-4 d-flex align-items-center justify-content-lg-center"
          >
            <div class="">
              <div class="bold text-white">Resources</div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Blog</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Career Advice</a>
              </div>
              <div class="pt-3">
                <!-- <a href="#" class="d-flex light text-white">FAQ</a> -->
                <router-link to="/FAQ" class="d-flex light text-white"
                  >FAQs</router-link
                >
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Terms of Service</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Help & Support</a>
              </div>
            </div>
          </div>
          <div
            class="col-lg-3 mb-lg-0 mb-sm-4 mb-4 d-flex justify-content-lg-center"
          >
            <div class="">
              <div class="bold text-white">Company</div>
              <div class="pt-3 -flex">
                <a href="#" class="d-flex light text-white">About Us</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Our Team</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Testimonials</a>
              </div>
              <div class="pt-3">
                <a href="#" class="d-flex light text-white">Privacy Policy</a>
              </div>
            </div>
          </div>
          <div class="col-lg-3 d-flex justify-content-lg-center">
            <div class="">
              <div class="bold text-white">Contact Us</div>
              <div class="pt-3 d-flex align-items-center">
                <img src="@/assets/images/sms.png" alt="" srcset="" />
                <a href="#" class="d-flex light text-white ps-2"
                  >Exapmle@mail.com</a
                >
              </div>
              <div class="pt-3 d-flex align-items-center">
                <img src="@/assets/images/call.png" alt="" srcset="" />
                <a href="#" class="d-flex light text-white ps-2"
                  >+1 123-456-7890</a
                >
              </div>
              <div class="pt-3 d-flex align-items-center">
                <img src="@/assets/images/location.png" alt="" srcset="" />
                <a href="#" class="d-flex light text-white ps-2"
                  >123 Main St, City, State</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-container border-bottom m-auto pt-5"></div>
      <p class="text-white text-center py-4 mb-0">
        © 2023, Job Crafted. All rights reserved
      </p>
    </footer>
  </div>
</template>
    
<script>
import "bootstrap";
import FrontendHeader from "./FrontendHeader.vue";
import "swiper/css";

export default {
  name: "HomePage",

  components: {
    FrontendHeader,
  },

  setup() {
    const onSwiper = (swiper) => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange,
    };
  },
  methods: {
    logout() {
      // Delete the accessToken key from storage
      localStorage.removeItem("accessToken"); // Replace with your storage mechanism

      // Redirect to the login page
      this.$router.push("/login");
    },
  },
  created() {
    // Check if the user is already logged in based on the presence of accessToken in storage
    const accessToken = localStorage.getItem("accessToken"); // Replace with your storage mechanism

    if (accessToken) {
      this.isLoggedIn = true;
    }
  },
  computed: {
    hasAccessToken() {
      // Check if "accessToken" exists in storage and return true or false accordingly
      const accessToken = localStorage.getItem("accessToken"); // You can replace 'localStorage' with 'sessionStorage' if needed
      return !!accessToken;
    },
  },
};
</script>
    
    <!-- Add your CSS styles here or link to an external CSS file if needed -->
<style scoped>
/* Your CSS styles go here */
@import "../assets/css/global.css";
/* Update the path as needed */
@import "bootstrap/dist/css/bootstrap.min.css";
</style>
    