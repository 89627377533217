<template>
  <section>
    <AdminHeader />
    <div class="dashboard-main">
      <div class="d-flex mx-0">
        <div class="dashboard-main-left sticky-top bg-black h-100">
          <div>
            <img
              src="@/assets/images/white-logo.png"
              class="dashbaord-whitelogo"
              alt=""
            />
          </div>
          <div class="timeline-dashboard">
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 1 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Personal Information
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 2 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Professional Summary
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 3 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Work Experience
              </div>
            </div>

            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 4 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">Education</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 5 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">Skills</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 active-timeline position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 6 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Projects or Achievements
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 7 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Additional Information
              </div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  <span class="bold"> 8 </span>
                  <img src="@/assets/images/check.png" alt="" srcset="" />
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">References</div>
            </div>
            <div
              class="d-flex align-items-center timeline-dashboard-1 position-relative"
            >
              <div
                class="parent-circle d-flex align-items-center justify-content-center"
              >
                <div
                  class="child-circle d-flex align-items-center justify-content-center bold text-white"
                >
                  9
                </div>
              </div>
              <div class="medium text-white ps-3 step-title">
                Final Review and Download
              </div>
            </div>
          </div>
          <!-- Links and copyright -->
          <div class="mb-3 mt-5 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Privacy Policy </span>
          </div>
          <div class="mb-3 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Terms & Conditions </span>
          </div>
          <div class="mb-3 d-lg-block d-none d-sm-none">
            <span class="text-FFBA52 text-underline"> Contact Us </span>
          </div>
          <div class="text-white fs-12 mt-5 d-lg-block d-none d-sm-none">
            © 2023, Job Crafted. All rights reserved
          </div>
        </div>
        <div class="dashboard-main-right ps-lg-5 ps-sm-1 ps-1">
          <div class="row dashboard-row">
            <div class="col-lg-9 dashboard-mid-col">
              <form id="addForm" method="post">
                <div class="dashboard-mid">
                  <h4 class="text-white bold mb-0">Additional Information</h4>
                  <p class="mb-0 text-white pt-2">
                    This could include languages spoken, professional
                    affiliations, volunteer work, or any other relevant details
                  </p>
                </div>
                <div class="mt-4">
                  <label class="bold text-black mb-2">Language</label>
                  <select
                    v-model="selectedLanguage"
                    name="selectedLanguage"
                    class="custom-input input-height w-100"
                  >
                    <option value="" selected disabled>
                      Enter languages spoken
                    </option>
                    <option value="English">English</option>
                    <option value="Spanish">Spanish</option>
                    <option value="Chinese">Chinese</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Arabic">Arabic</option>
                  </select>
                </div>
                <div class="mt-4">
                  <label class="bold text-black mb-2"
                    >Professional Affiliations</label
                  >
                  <input
                    v-model="professionalAffiliations"
                    name="professionalAffiliations"
                    class="w-100 custom-input input-height"
                    placeholder="Enter professional affiliations"
                    type="text"
                  />
                </div>
                <div class="mt-4">
                  <label class="bold text-black mb-2">Volunteer Work</label>
                  <textarea
                    v-model="volunteerWork"
                    name="volunteerWork"
                    placeholder="Enter details of volunteer work"
                    cols="12"
                    rows="3"
                    class="w-100 custom-input"
                  ></textarea>
                </div>
                <div class="mt-4">
                  <div class="d-flex justify-content-between">
                    <button
                      type="button"
                      class="border-0 py-2 px-4 btn-skip px-4"
                    >
                      Skip
                    </button>
                    <button
                      class="border-0 py-2 px-4 text-white btn-next px-4"
                      type="button"
                      @click="saveAdditionalInfoData"
                    >
                      Next Step
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-3 ps-lg-4 ps-sm-3 ps-3 mt-lg-0 mt-sm-4 mt-4">
              <img src="" class="w-100" alt="" srcset="" />
              <div class="pt-3">
                <button
                  class="outline-btn-pdf w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <img src="@/assets/images/eye.png" alt="" srcset="" /> &nbsp;
                  <span
                    class="text-009473 medium"
                    @click="viewUserResume('default')"
                    >View Resume</span
                  >
                </button>
              </div>
              <div class="pt-3">
                <button
                  class="outline-btn-pdf-black w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                >
                  <img
                    src="@/assets/images/refresh-circle.png"
                    alt=""
                    srcset=""
                  />
                  &nbsp;
                  <span class="text-black medium">Apply Template</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <selectTemplateModal />
  </section>
</template>

<script>
import "bootstrap";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css"; // This imports Bootstrap JavaScript
import axios from "axios"; // Import Axios
import $ from "jquery";
import AdminHeader from "./AdminHeader.vue";
import selectTemplateModal from './selectTemplateModal.vue'

export default {
  components: {
    AdminHeader,
    selectTemplateModal
  },

  mounted() {
    const accessToken = localStorage.getItem("accessToken");

    // Create a data object to send with the request
    const requestData = {
      accessToken: accessToken,
    };
    // Make a POST request to your API endpoint
    axios
      .post(
        "https://admin1.jobcrafted.com/api/get-additional-info",
        requestData
      )
      .then((response) => {
        // Handle a successful response here
        if (response.data) {
          if (response.data.result == "temp") {
            // Get the saved form data from localStorage
            const savedFormData = localStorage.getItem("additionalFormData");

            // Parse the saved JSON data into a JavaScript object
            const formDataObject = JSON.parse(savedFormData);

            // Define a response.data object with the desired properties and values
            response.data = formDataObject;
          }
          this.selectedLanguage = response.data.selectedLanguage || "";
          this.professionalAffiliations =
            response.data.professionalAffiliations || "";
          this.volunteerWork = response.data.volunteerWork || "";
          toast("Data Ftched Successfully", {
            autoClose: 5000,
            position: "top-right",
            type: "success",
            closeOnClick: true,
            pauseOnHover: true,
            progressBar: true,
            timeout: 9000,
            showCloseButton: true,
            className: "custom-toast",
            html: true,
          });
        } 
        // this.$router.push('/personal_summary');
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error sending data", error);
        toast('Error sending data"', {
          autoClose: 5000,
          position: "top-right",
          type: "error",
          closeOnClick: true,
          pauseOnHover: true,
          progressBar: true,
          timeout: 9000,
          showCloseButton: true,
          className: "custom-toast",
          html: true,
        });
      });
  },
  data() {
    return {
      selectedLanguage: "", // Data binding for selected language
      professionalAffiliations: "", // Data binding for professional affiliations
      volunteerWork: "", // Data binding for volunteer work
    };
  },

  name: "additionalInfo",
  methods: {
    changeTemplate(template) {
      $("#template").val(template);
      var colorToApply = $("#color").val();
      const accessToken = localStorage.getItem("accessToken");
      $(".small-image-pdf").removeClass("activeSelect");
      $("." + template).addClass("activeSelect");
      // Define the key-value pairs
      var formData = new FormData();
      const data = {
        professionalForm: {
          professionaSummary: "aaa",
          skills: "",
          access_token: "null",
        },
        workExperienceForm: {
          company_name: "Cricket",
          start_month: "March",
          start_year: "1944",
          end_month: "3",
          end_year: "1949",
          skills: "Marketing Specialist",
          access_token: "null",
        },
        educationFormData: {
          certification: "AWS Certified Solutions Architect – Associate",
          institute: "University of Oxford",
          graduate_date: "3",
          graduate_month: "May",
          graduate_year: "1945",
          skills: "",
          access_token: "null",
        },
        skillFormData: { skills: "as,as", access_token: "null" },
        projectsForm: {
          projectDescription: "as",
          projectOutcomes: "asa",
          access_token: "null",
        },
        additionalFormData: {
          selectedLanguage: "Chinese",
          professionalAffiliations: "NoAffiliation",
          volunteerWork: "no work",
          access_token: "null",
        },
        referenceFormData: {},
      };

      // Loop through the key-value pairs and append them to the FormData object
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          // Convert the object to a JSON string and append it to FormData
          formData.append(key, JSON.stringify(data[key]));
        }
      }
      // Create a data object to send with the request
      const requestData = {
        accessToken: accessToken,
        template: template,
        colorToApply: colorToApply,
        formData: formData,
      };
      axios
        .post(
          "https://admin1.jobcrafted.com/api/get-reference-info-template",
          requestData
        )
        .then((response) => {
          // Handle a successful response here
          if (response.data) {
            $("#templateModal").html(response.data);
            $("#templateModalChoose").html(response.data);

            toast("Template Applied Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            toast("Template can be applied", {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
          // this.$router.push('/personal_summary');
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);
        });
    },
    viewUserResume(templateName) {
      const formData = new FormData();
      const accessToken = localStorage.getItem("accessToken");
      formData.append("accessToken", accessToken);
      formData.append("template", templateName);
      axios
        .post(
          "https://admin1.jobcrafted.com/api/get-reference-info-template",
          formData
        )
        .then((response) => {
          $("#templateModal").html(response.data);
          // return;
          // Handle a successful response here
          if (response.data.code == 200) {
            toast("Data Saved Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          } else {
            this.validationErrors = response.data.result;
            $("#errorDiv").append(`<p class="mb-0 text-red pt-2"></p>`);
            $("#errorDiv").append(
              `<p class="mb-0 text-red pt-2"> - ${this.validationErrors}</p>`
            );
            toast(this.validationErrors[0], {
              autoClose: 5000,
              position: "top-right",
              type: "error",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);

          // Display an error message to the user if needed
        });
    },
    saveAdditionalInfoData() {
      // Get the form data
      const formData = new FormData(document.getElementById("addForm"));
      const accessToken = localStorage.getItem("accessToken");
      formData.append("access_token", accessToken);
      // Make a POST request to your API endpoint
      axios
        .post(
          "https://admin1.jobcrafted.com/api/save-additional-info",
          formData
        )
        .then((response) => {
          // Handle a successful response here
          if (response.data.code == 200) {
            if (response.data.result === "temp") {
              const formData = new FormData(document.getElementById("addForm"));
              const accessToken = localStorage.getItem("accessToken");
              formData.append("access_token", accessToken);
              localStorage.setItem(
                "additionalFormData",
                JSON.stringify(Object.fromEntries(formData.entries()))
              );
            }
            toast("Data Saved Successfully", {
              autoClose: 5000,
              position: "top-right",
              type: "success",
              closeOnClick: true,
              pauseOnHover: true,
              progressBar: true,
              timeout: 9000,
              showCloseButton: true,
              className: "custom-toast",
              html: true,
            });
            this.$router.push("/build-resume-reference");
          } else {
            this.validationErrors = response.data.result;
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error sending data", error);

          // Display an error message to the user if needed
        });
    },
  },
};
</script>

<style scoped>
/* Your component-specific styles go here */
.validation-errors {
  color: red;
}
</style>

<!-- Add your CSS styles here or link to an external CSS file if needed -->
<style scoped>
/* Import CSS */
@import "@/assets/css/global.css";
@import "@/assets/css/dashboard.css";

@import "bootstrap/dist/css/bootstrap.min.css";
</style>
